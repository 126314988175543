import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link', 'phoneLink']

  reportOutbound() {
    var href = this.linkTarget.getAttribute('href');
    var text = this.linkTarget.textContent;
    var url = "/outbound_link.js?url=" + href + '&text=' + text;
    Rails.ajax({
      type: 'post',
      url: url
    });
  }

  trackPhoneLink(memberToken) {
    var etjsElement = document.getElementById('et');
    var analyticsController = this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics');
    var member = this.data.get('member');
    var url = '/leads?kind=call&profile_id=' + member;

    Rails.ajax({
      type: 'post',
      url: url
    });

    analyticsController.trackEvent({
      'name': 'lead',
      'action': 'call',
      'label': member
    });
  }

}
