import { Controller } from '@hotwired/stimulus'
import maguire from 'maguire'

export default class extends Controller {

  initialize() {
    maguire.showMeTheMoney({
          parentID: 'currency-select-wrapper'
        , addCode: 0
        , template: 'lre'
        , codeOnly: 1
      });

      document.addEventListener('turbo:before-cache', function() {
        if (document.getElementById('maguire-select')) {
          maguire.tidyUp();
        }
      });
  }

}
