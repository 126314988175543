import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['inquiry', 'phone', 'preferredContactMethod']
  
  connect() {
    // Form validation
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var formsToValidate = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(formsToValidate, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
    
  }

  updatePreferredContactMethod(e) {
    
    var phoneFields = document.querySelectorAll('.phone input');
    var smsFields = document.querySelectorAll('.contactMethod-sms');
    var emailFields = document.querySelectorAll('.contactMethod-email');
    
    [].forEach.call(phoneFields, function(field) {
      
      if (e.target.value === 'text_sms') {
        field.required = true;
        
        [].forEach.call(smsFields, function(input) {
          input.checked = true;
        });
        
        [].forEach.call(emailFields, function(input) {
          input.checked = false;
        });
        
      } else {
        field.required = false;
        
        [].forEach.call(smsFields, function(input) {
          input.checked = false;
        });
        
        [].forEach.call(emailFields, function(input) {
          input.checked = true;
        });
      }
      
    });
    
  }
  
  uploadImage(event) {
    let inputField = event.target;
    let file = inputField.files[0];
    let srcUrl = URL.createObjectURL(file);
    let previewEl = document.querySelector('#preview-' + inputField.dataset.id);
    let labelEl = document.querySelector('#label-' + inputField.dataset.id);
    
    labelEl.style.display = 'none';
    
    previewEl.classList.add('uploaded');
    previewEl.querySelector('img').src = srcUrl;
  }

  trackInquiry() {
    var inquiryObj = this.data.get('item');
    var listHubKey = this.data.get('lkey');
    
    this.analyticsController.trackLead(inquiryObj, listHubKey);
    
    // Adfenix tracking
    if (window.adfenix) {
      window.adfenix.track('contactrequestsubmit');
    }
    
  }

  get analyticsController() {
    var etjsElement = document.getElementById('et');
    return this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics')
  }

}
