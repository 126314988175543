import { Controller } from '@hotwired/stimulus'
import * as luxestrap from 'luxestrap'

export default class extends Controller {

  static targets = ['listingCard', 'mapToggle']

  mapToggleTargetConnected() {
    this.checkForLocation(window.location.search);
  }

  listingCardTargetConnected(card) {
    if (card.dataset.lhKey) {
      var lhKey = JSON.parse(card.dataset.lhKey);
      this.analyticsController.trackSearchResults(lhKey);
    }
  }

  getSearchQuery() {
    var searchParams = [];
    var form = document.getElementById('search-parameters');
    var inputs = form.getElementsByTagName('input');
    var $selects = jQuery('select.selectpicker:not(#saved_search_email_frequency)');
    var areaInput = document.getElementById('area');
    var areaValue = '';
    var countryValue = '';
    var subdivisionValue = '';

    // Get select values
    jQuery.each($selects, function(index, select){
      var selectedOption = jQuery(select).find('option:selected').val();
      if (selectedOption != ''){
        if (jQuery(select).hasClass('location')) {
          if (jQuery(select).hasClass('subdivisions')) {
            subdivisionValue = selectedOption;
          } else {
            countryValue = selectedOption;
          }
        } else {
          searchParams.push(jQuery(select).attr('name') + '=' + selectedOption);
        }
      }
    });

    // Set area input value
    if (subdivisionValue != '' || countryValue != '') {
      let countryWithHiddenSubdivision = (countryValue.length > 0 && areaInput.value.startsWith(countryValue)) ? areaInput.value : countryValue;
      areaInput.value = subdivisionValue != '' ? subdivisionValue : countryWithHiddenSubdivision;
    }

    [].forEach.call(inputs, function(input) {

      if ( !input.classList.contains('skip') ){
        if (input.type == 'checkbox') {
          if (input.checked == true) {
            searchParams.push(input.name + '=' + input.value);
          }
        } else if (input.value != '' && input.name != '') {
          searchParams.push(input.name + '=' + input.value);
        }
      }
    });

    var query = searchParams.join('&');
    return query;

  }

  paginate(event) {
    event.preventDefault();
    this.getResults(this.data.get('url'));
    this.trackSearchResultsPageviews();
  }

  trackSearchResultsPageviews() {
    this.analyticsController.trackPage();
  }

  submit(event) {

    if (event) {
      event.preventDefault();
    }

    var that = this;
    var predictiveSearch = document.querySelector('#predictive-search .suggestions li.selectedLi a.selected, #predictive-search .suggestions li.initial a');

    if (predictiveSearch) {
      this.visit(predictiveSearch.href);
    } else {
      setTimeout(function() {
        var query = that.getSearchQuery();
        var baseURL = location.pathname;
        var url = baseURL + '?' + query;
        that.getResults(url);
        that.checkForLocation(url);
      }, 100);
    }
    closeSuggestions('#q', '#predictive-search');

  }

  visit(url) {
    window.location.href = url;
  }

  getResults(url) {
    var that = this;
    Rails.ajax({
      type: 'get',
      url: url,
      success: function(response) {
        that.updateResults(response);
        that.updateURL(url);
      },
      error: function(response) {
        console.log('ajax error: ' + response);
      }
    });
  }

  updateResults(results) {
    if (location.pathname.includes('map')) {
      luxestrap.mapkitHelpers.updateMapResults(results);
    } else {
      var el = document.getElementById('results');
      el.innerHTML = results;
    }
    this.sidebarController.setResultsMarginBottom();
  }

  displayAllListings() {
    document.getElementById('flash-results').classList.add('d-none');
    document.getElementById('profileId').value = '';
    document.getElementById('developmentId').value = '';
    document.getElementById('profileName').value = '';

    // Submit form with reset values
    var that = this;
    setTimeout(function() {
      that.getResults(location.pathname);
    }, 100);
  }

  checkForLocation(query) {
    var searchQuery = query ? query : location.search;
    var disabled = (searchQuery.includes('area') || searchQuery.includes('city') || searchQuery.includes('map_bounds')) ? false : true;

    var mapLinks = document.querySelectorAll('.map-link');
    [].forEach.call(mapLinks, function(mapLink) {
      if (disabled) {
        mapLink.classList.add('disabled');
        mapLink.disabled = true;
        jQuery('.map-tab-tooltip').tooltip({
          placement: 'left',
          trigger: 'hover',
          offset: 10
        }).tooltip('enable');
      } else {
        mapLink.classList.remove('disabled');
        mapLink.disabled = false;
        jQuery('.map-tab-tooltip').tooltip('disable');
      }
    });
  }

  toggleGallery() {
    var mapOptions = document.querySelectorAll('.map-link');
    var galleries = document.querySelectorAll('.gallery-option');
    [].forEach.call(mapOptions, function(mapOption) {
      mapOption.classList.remove('active');
    });
    [].forEach.call(galleries, function(gallery) {
      gallery.classList.add('active');
    });
    var params = this.getSearchQuery();
    var subpath = window.location.pathname.replace('/map', '');
    var url = subpath + '?' + params;
    window.location.href = url;

  }

  toggleMap(e) {
    e.preventDefault();
    var mapOptions = document.querySelectorAll('.map-link');
    if (!mapOptions[0].classList.contains('disabled')) {
      var galleries = document.querySelectorAll('.gallery-option');
      [].forEach.call(galleries, function(gallery) {
        gallery.classList.remove('active');
      });
      [].forEach.call(mapOptions, function(mapOption) {
        mapOption.classList.add('active');
      });
      var params = this.getSearchQuery();
      var subpath = window.location.pathname.replace('/map', '');
      var url = subpath + '/map?' + params;
      window.location.href = url;
    }
  }

  setMapBounds() {
    var auSelect = document.getElementById('AU-subdivisions');
    var caSelect = document.getElementById('CA-subdivisions');
    var usSelect = document.getElementById('US-subdivisions');

    if (auSelect && jQuery('.AU-subdivisions option:selected').val().length > 0) {
      var $selectedCountry = jQuery('.AU-subdivisions option:selected');
    } else if (caSelect && jQuery('.CA-subdivisions option:selected').val().length > 0) {
      var $selectedCountry = jQuery('.CA-subdivisions option:selected');
    } else if (usSelect && jQuery('.US-subdivisions option:selected').val().length > 0) {
      var $selectedCountry = jQuery('.US-subdivisions option:selected');
    } else if (jQuery('.country option:selected').val().length > 0) {
      var $selectedCountry = jQuery('.country option:selected');
    }
    var boundingRegion = new mapkit.BoundingRegion($selectedCountry.data('ne-lat'), $selectedCountry.data('ne-lng'), $selectedCountry.data('sw-lat'), $selectedCountry.data('sw-lng'));
    var region = boundingRegion.toCoordinateRegion();

    var map = mapkit.maps[0];
    if (map) {
      map.setRegionAnimated (region, true);
    }
  }

  updateURL(url) {
    var searchForm = document.getElementById('search-parameters');
    history.replaceState(null, '', url);
    window.onpopstate = function(event) {
      Turbo.visit(document.location)
    }
    this.checkForLocation(url);
  }

  resetSubdivisions() {
    document.getElementById('area').value = '';
    jQuery('.selectpicker.subdivisions').selectpicker('val', '').addClass('d-none');
  }

  compileCheckboxGroupParam(event) {
    var input = event.target;
    var hiddenInput = document.getElementById(this.data.get('param'));
    var setValue = hiddenInput.value.split(',');
    var newValue = [];

    openCloseCheckboxGroup(input);

    var selectedValue = input.value;

    var selectedFields = document.querySelectorAll('input.active.' + this.data.get('param'));

    if (input.classList.contains('active') && !input.checked) {
      setValue = setValue.filter(function(el) {
        return el != selectedValue;
      });

    }  else {
      setValue.push(selectedValue);

      if (input.dataset.parent) {
        setValue = setValue.filter(function(el) {
          return el != input.dataset.parent;
        });
      }
    }

    [].forEach.call(selectedFields, function(field) {
      newValue.push(field.value);
    });

    this.sidebarController.setResultsMarginBottom();

    // Sanitize and set values
    hiddenInput.value = newValue.filter(stripDuplicateAndEmptyValues).join();

    // Submit form
    this.submit();

  }

  reset() {

    var isMapSearch = location.pathname.includes('map');
    var searchForm = document.getElementById('search-parameters');

    // Reset range sliders
    var sliders = searchForm.getElementsByClassName('range-slider');
    if (sliders) {
      [].forEach.call(sliders, function(slider) {
        slider.noUiSlider.set([slider.noUiSlider.options.range.min, slider.noUiSlider.options.range.max]);
      });
    }

    // Reset input values
    var inputs = searchForm.getElementsByTagName('input');
    if (inputs) {

      [].forEach.call(inputs, function(input) {
        if (!isMapSearch && !input.classList.contains('location') && input.type != 'checkbox') {
          input.value = '';
        }
      });
    }
    jQuery('#flash-results').alert('close');
    document.querySelector('#predictive-search ul').innerHTML = '';

    // Reset checkboxes & checkbox groups
    var checkboxes = searchForm.querySelectorAll('input[type=checkbox]');
    var checkboxGroups = searchForm.querySelectorAll('.checkbox-group');
    if (checkboxes) {
      [].forEach.call(checkboxes, function(checkbox) {
        checkbox.checked = false;
        checkbox.classList.remove('active');
      });
    }
    if (checkboxGroups) {
      [].forEach.call(checkboxGroups, function(group) {
        if (group.classList.contains('collapsible')) {
          group.classList.remove('open');
          group.classList.add('closed');
        }
      });
    }

    // Reset selects
    if (isMapSearch) {
      jQuery('.selectpicker:not(.location)').selectpicker('val', '');
    } else {
      jQuery('.selectpicker').selectpicker('val', '');
    }

    // Submit form with reset values
    var that = this;
    setTimeout(function() {
      that.getResults(location.pathname);
    }, 300);
  }

  geoLocationSearch() {
    var searchQuery = document.getElementById('q').value;

    if( navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, {enableHighAccuracy: true, timeout : 5000});
    }

    // geo search functions
    function geoSuccess(position) {
      if (position.coords.latitude == 0 && position.coords.longitude == 0) {
        alert("Unable to get location");
      } else {
        var geocenter = position.coords.latitude + "," + position.coords.longitude;
        var searchURL = document.getElementById('home-search-type').value;
        var query = document.getElementById("q").value;
        window.location.href = searchURL + "?geocenter=" + geocenter + "&distance=100&sort=distance&q=" + query;
      }
    }

    function geoError(error) {
      if (error.code == 1) {
        alert("User denied access to location");
      } else {
        alert("Unable to get location");
      }
    }
  }

  get sidebarController() {
    var openSidebarElement = document.getElementById('openSlidebar');
    return this.application.getControllerForElementAndIdentifier(openSidebarElement, 'sidebar')
  }

  get analyticsController() {
    var etjsElement = document.getElementById('et');
    return this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics')
  }

}

// Unrender suggestions
function closeSuggestions(input, suggestionsObj) {
  jQuery(input).removeData();
  document.querySelector(suggestionsObj +' ul').innerHTML = '';
}

function stripDuplicateAndEmptyValues(value, index, self) {
  return value != '' && self.indexOf(value) === index;
}

function openCloseCheckboxGroup(checkbox) {
  var dupes = document.querySelectorAll('input[value="' + checkbox.value + '"]:not([type="hidden"])');

  [].forEach.call(dupes, function(input) {
    var inputGroup = input.parentElement;
    var parent = document.getElementById(input.dataset.parent);
    var activeChildren = inputGroup.querySelectorAll('input.active[type="checkbox"]');

    if (input.classList.contains('active') || inputGroup.classList.contains('open')) {

      inputGroup.style.maxHeight = '2500px';

      input.classList.remove('active');
      inputGroup.classList.remove('open');
      inputGroup.classList.add('closed');
      input.checked = false;

      var activeSiblings = document.querySelectorAll('.checkbox-group.open[data-parent="' + input.dataset.parent + '"] input.active');
      var openSiblings = document.querySelectorAll('.checkbox-group.open[data-parent="' + input.dataset.parent + '"]');

      if (parent && input == checkbox && activeSiblings.length < 1) {
        parent.classList.remove('open');
        inputGroup.classList.add('closed');
        parent.classList.add('active');

      } else {
        if (parent && input != checkbox) {
          if (activeSiblings.length == 0 && openSiblings.length == 0) {
            var falseSelections = checkbox.parentElement.parentElement.querySelectorAll('.checkbox-group.open');
            [].forEach.call(falseSelections, function(selection) {
              selection.classList.remove('open');
              selection.classList.add('closed');
              var childrenInputs = selection.querySelectorAll('input');
              [].forEach.call(childrenInputs, function(child) {
                child.checked = false;
              });
            });
          }
        }
      }

      if (activeChildren) {
        [].forEach.call(activeChildren, function(child) {
          child.classList.remove('active');
          child.checked = false;
          child.parentElement.classList.remove('open');
          child.parentElement.classList.add('closed');
        });
      }

      setTimeout(function() {
        inputGroup.style.maxHeight = null;
      }, 400);

    } else {

      input.classList.add('active');
      inputGroup.classList.add('open');
      inputGroup.classList.remove('closed');
      input.checked = true;

      if (parent) {
        parent.classList.remove('active');
      }

    }

  }); // forEach END
}
