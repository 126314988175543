import { Controller } from '@hotwired/stimulus'
import etjs from 'etjs'
import ahoy from 'ahoy.js'

export default class extends Controller {

  static targets = ['et']

  initialize() {
    this.phoneHome();
  }

  trackVisit() {
    var page_view_url = this.data.get('pageViewUrl');
    if(page_view_url) {
      Rails.ajax({
        type: 'post',
        url: page_view_url
      });
    }

  }

  phoneHome() {
    var etjsObj = this.createPageOptionsObj();
    etjs.turbolinksInit(etjsObj);
  }

  createPageOptionsObj() {
    var that = this;
    var etElement = document.getElementById('et');
    var etElementValues = JSON.parse(etElement.dataset.analyticsInfo);
    var etjsObj = {
      gaCode: 'UA-1965509-2',
      gaV4Code: 'G-RRXL4BBBM4',
      isProduction: etElementValues.isProduction,
      fireWithTrackPage: function () {
        ahoy.trackView();
        that.trackVisit();
      },
      debugger: !etElementValues.isProduction,
      pageOpts: {
        listingID: etElementValues.listingID,
        listingPostalCode: etElementValues.listingPostalCode,
        listingCountry: etElementValues.listingCountry,
        listingStateProvince: etElementValues.listingStateProvince,
        listingCity: etElementValues.listingCity,
        memberToken: etElementValues.memberToken,
      }
    };
    return etjsObj;
  }

  trackPage() {
    var etjsObj = this.createPageOptionsObj();
    etjs.trackPage(etjsObj);
  }


  trackEvent(event) {
    if (typeof event == 'string') {
      var eventObj = {
        'name': event,
        'action': 'send'
      }
    } else {
      var eventObj = event;
    }
    etjs.trackEvent(eventObj);
  }

  trackLead(inquiryObj) {

    this.trackEvent({
      'name': 'lead',
      'action': 'inquiry',
      'label': inquiryObj
    });

  }

}
