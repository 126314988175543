import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  openSlideoutSidebar() {
    openSidebar();
    addOverlay();
    this.setResultsMarginBottom();

    document.addEventListener('click', this.trackOutsideClicks);
  }

  closeSlideoutSidebar() {
    closeSidebar();
    removeOverlay();
    this.resetResultsMarginBottom();

    document.removeEventListener('click', this.trackOutsideClicks);
  }

  toggleSlideoutSidebar() {
    var sidebar = document.getElementById('slideout-sidebar');
    if (sidebar.classList.contains('open')) {
      this.closeSlideoutSidebar();
    } else {
      this.openSlideoutSidebar();
    }
  }

  setResultsMarginBottom() {
    var results = document.getElementById('results');
    var sidebar = document.getElementById('slideout-sidebar');
    var mb = (sidebar.offsetHeight - results.offsetHeight) > 0 ? (sidebar.offsetHeight - results.offsetHeight) : 0;
    var mq = window.matchMedia('(min-width: 768px)');

    if (mq.matches) {
      results.style.marginBottom = '';
    } else {
      results.style.marginBottom = mb + 'px';
    }
  }

  resetResultsMarginBottom() {
    resetResultsHeight();
  }

  trackOutsideClicks(event) {
    var sidebar = document.getElementById('slideout-sidebar');
    var that = this;

    if (event.target.id == 'sidebar-open-overlay') {
      closeSidebar();
      removeOverlay();
      resetResultsHeight();

      if (sidebar.offsetHeight > document.body.offsetHeight) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }
}

function openSidebar() {
  var sidebar = document.getElementById('slideout-sidebar');
  sidebar.classList.add('open');
}

function closeSidebar() {
  var sidebar = document.getElementById('slideout-sidebar');
  sidebar.classList.remove('open');
}

function addOverlay() {
  var overlay = document.createElement('div');
  var sidebar = document.getElementById('slideout-sidebar');
  overlay.id = 'sidebar-open-overlay';
  overlay.style.minHeight = (sidebar.offsetHeight + 140) + 'px';

  document.body.appendChild(overlay);
}

function removeOverlay() {
  var overlay = document.getElementById('sidebar-open-overlay');
  overlay.remove();
}

function resetResultsHeight() {
  var results = document.getElementById('results');
  results.style.marginBottom = '';
}
