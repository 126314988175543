import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["savedSearchForm"]

  updateSavedSearch() {
    Rails.fire(this.savedSearchFormTarget, 'submit')
  }

}
