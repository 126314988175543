import { Controller } from '@hotwired/stimulus'

var data, searchURL, searchType;

export default class extends Controller {
  static targets = ['keywordInput', 'typeSelect']

  initialize() {
    this.getData();
  }

  getSearchType(url) {
    var type;
    switch (url) {
    case '/residential/rentals':
      type = 'rentals';
      break;
    case '/residential/private_islands':
      type = 'private_islands';
      break;
    case '/vacation_rentals':
      type = 'vacation_rentals';
      break;
    case '/residential/farm_and_ranch':
      type = 'farm_and_ranch';
      break;
    case '/residential/lots_and_land':
      type = 'lots_and_land';
      break;
    case '/professionals/search':
      type = 'members';
      break;
    case '/professionals/agents':
      type = 'agents';
      break;
    case '/developments':
      type = 'developments';
      break;
    default:
      type = 'residential';
      break;
    }
    return type;
  }

  setSearchData() {
    closeSuggestions('#q', '#predictive-search');
    searchURL = this.typeSelectTarget.value
    searchType = this.getSearchType(searchURL);
  }

  getData() {
    searchURL = document.getElementById('home-search') ? jQuery('select').selectpicker('val') : window.location.pathname;
    searchType = this.getSearchType(searchURL);
    this.getLocations(searchType, function(data) {
      data = data;
    });
  }

  getLocations(type, callback) {
    var base_url;
    if (window.location.href.includes('luxuryrealestate.com')) {
      base_url = 'https://s3.amazonaws.com/luxre.com-data/p/predictive_search/';
    } else if (window.location.href.includes('staging')) {
      base_url = 'https://s3.amazonaws.com/luxre.com-data/s/predictive_search/';
    } else {
      base_url = '/api/predictive_search/';
    }
    jQuery.getJSON(base_url + type + '.json', callback);
  }

  listenForKeypresses(e) {
    if (data) {
      suggestionEvents('#predictive-search', '#q', data, searchURL);
    } else {
      this.getLocations(searchType, function(data) {
        suggestionEvents('#predictive-search', '#q', data, searchURL, e);
      });
    }
  }

  homeSubmit(e) {
    e.preventDefault();
    var q = document.querySelector('input').value.replace(/[^a-zA-Z-'\d\s:]/g, '');
    searchURL += '?q=' + q.replace(/ /g, '+');

    var placeURL = jQuery("#predictive-search ul li.moveLi.selectedLi a").first().attr("href") ? jQuery("#predictive-search ul li.moveLi.selectedLi a").first().attr("href") : jQuery("#predictive-search ul li.moveLi:not(.keyword) a").first().attr("href");

    // If predictive places are present, use the first place
    if ( placeURL ) {
      searchURL = placeURL
    }
    window.location.href = searchURL;
  }

  reset() {
    closeSuggestions('#q', '#predictive-search')
  }
}
function suggestionEvents(suggestionsObj, input, optionList, searchUrl, e) {

  e.preventDefault();
  var val = document.querySelector(input).value;
  if(val.length > 0) {
    // Do not search if any of these keys are pressed:
    // Esc / Down / Up / Enter / Command(left and right) / Command(Firefox) / Tab keys
    if(e.keyCode !== 27 && e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13 && e.keyCode !== 91 && e.keyCode !== 93 && e.keyCode !== 224 && e.keyCode !== 9) {
      // jQuery(input).removeData();
      displaySuggestions(suggestionsObj, input, optionList, searchUrl);
    } else if (e.keyCode === 27) { // esc key
      closeSuggestions(input, suggestionsObj);
    } else if (e.keyCode === 38 || e.keyCode === 40) { // Down/Up arrow keys
      e.preventDefault();
      e.keyCode === 38 ? previousSuggestion(suggestionsObj, input) : nextSuggestion(suggestionsObj, input);
    }
  } else {
    closeSuggestions(input, suggestionsObj);
  }
}

function getSuggestions(q, optionList, searchUrl) {
  this.optionList = optionList;
  this.q = q;//.replace(/[^a-zA-Z-'\d\s:]/g, '');
  this.searchUrl = searchUrl;
}

getSuggestions.prototype = {

  // Compare input to supplied list
  beginsWith: function(location) {
    if(typeof(location) !== 'undefined') {
      //location = location.replace(/[^a-zA-Z-'\d\s:]/g, '');
      return location.toLowerCase().substr(0, this.q.length) === this.q.toLowerCase();
    }
    return false;
  },

  matchesAltName: function(alternates) {
    var names = alternates.toLowerCase();
    var query = this.q.toLowerCase();
    if(names.includes(query)) {
      var alts = names.split(', ');
      for(var i = 0; i < alts.length; i++) {
        if((alts[i].substring(0, query.length) == query)) { // || alts[i].includes(' ' + query)) {
          return true;
        }
      }
    }
    return false;
  },

  // Create suggestion array
  placeSuggestions: function() {
    var places = [];
    var altPlaces = [];
    var that = this;

    jQuery.each(this.optionList.places, function() {
      if(this.state && typeof(I18n.t("iso3166." + this.state)) !== 'undefined') {

        switch(true) {
          case that.beginsWith(this.city + ' ' + this.state.substr(3, this.state.length) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + this.state.substr(3, this.state.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + I18n.t("iso3166." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("iso3166." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + I18n.t("us_states." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("us_states." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
            var city = {
              city: this.city,
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            places.push(city);
            break;
          case that.matchesAltName(this.names):
            var city = {
              city: this.city,
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            altPlaces.push(city);
            break;
          case that.beginsWith(this.state.substr(3, this.state.length) +  ' ' + this.country):
          case that.beginsWith(this.state.substr(3, this.state.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(I18n.t("iso3166." + this.state) +  ' ' + this.country):
          case that.beginsWith(this.state_plain):
          case that.beginsWith(I18n.t("iso3166." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(I18n.t("us_states." + this.state) +  ' ' + this.country):
          case that.beginsWith(I18n.t("us_states." + this.state) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.state_names && that.matchesAltName(this.state_names):
            var state = {
              state: this.state,
              stateI18n: I18n.t("iso3166." + this.state),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            if (I18n.t("us_states." + this.state)) {
              state.fullState = I18n.t("us_states." + this.state);
            } else if (I18n.t("iso3166." + this.state)) {
              state.fullState = I18n.t("iso3166." + this.state);
            }

            places.push(state);
            break;
          case this.region && that.beginsWith(this.region.substr(3, this.region.length) +  ' ' + this.country):
          case this.region && that.beginsWith(this.region.substr(3, this.region.length) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.region && that.beginsWith(I18n.t("iso3166." + this.region) +  ' ' + this.country):
          case this.region && that.beginsWith(I18n.t("iso3166." + this.region) +  ' ' + I18n.t("country_codes." + this.country)):
          case this.region_names && that.matchesAltName(this.region_names):
            var state = {
              state: this.region,
              stateI18n: I18n.t("iso3166." + this.region),
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };

            if (I18n.t("us_states." + this.region)) {
              state.fullState = I18n.t("us_states." + this.region);
            } else if (I18n.t("iso3166." + this.region)) {
              state.fullState = I18n.t("iso3166." + this.region);
            }

            places.push(state);
            break;

          case that.beginsWith(this.country):
          case that.beginsWith(I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.country_plain):
          case this.country_names && that.matchesAltName(this.country_names):
            var country = {
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(country);
            break;
        }
      } else {
        switch(true) {
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.city + ' ' + this.country):
          case that.beginsWith(this.city + ' ' + I18n.t("country_codes." + this.country)):
            var city = {
              city: this.city,
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(city);
            break;
          case that.matchesAltName(this.names):
            var city = {
              city: this.city,
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            altPlaces.push(city);
            break;
          case that.beginsWith(this.country):
          case that.beginsWith(I18n.t("country_codes." + this.country)):
          case that.beginsWith(this.country_plain):
          case this.country_names && that.matchesAltName(this.country_names):
            var country = {
              country: this.country,
              countryI18n: I18n.t("country_codes." + this.country)
            };
            places.push(country);
            break;
        }
      }
    });

    Array.prototype.push.apply(places, altPlaces);
    return places;
  },

  developmentSuggestions: function() {
    var developments = [];
    var contains = []
    var developmentCityBegins = [];
    var developmentCityContains = [];
    var developmentCountryContains = [];
    var developmentStateContains = [];
    var that = this;

    jQuery.each(this.optionList.developments, function() {
      var cleanedName = this.name.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g,"");
      var cleanedQuery = that.q.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedCity = this.city && this.city.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedState = (this.state && typeof(I18n.t("iso3166." + this.state)) !== 'undefined') ? I18n.t("iso3166." + this.state).toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";
      var cleanedRegion = (this.region && typeof(I18n.t("iso3166." + this.region)) !== 'undefined') ? I18n.t("iso3166." + this.region).toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";
      var cleanedCountry = this.country && typeof(I18n.t("country_codes." + this.country)) !== 'undefined' ? I18n.t("country_codes." + this.country).toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";

      // Check if name _starts_ with query first then check for results that _contain_ query
      if (cleanedName.substring(0, cleanedQuery.length) == cleanedQuery) {
        developments.push(this);
      } else if (cleanedName.includes(cleanedQuery)) { // Then check for names that _contain_ query
        contains.push(this);
      } else if (this.city && cleanedCity.substring(0, cleanedQuery.length) == cleanedQuery) {
        developmentCityBegins.push(this);
      } else if (this.city && cleanedCity.includes(cleanedQuery)) {
        developmentCityContains.push(this);
      } else if (cleanedState.includes(cleanedQuery) || cleanedRegion.includes(cleanedQuery)) {
        developmentStateContains.push(this);
      } else if (this.state_plain && this.state_plain.includes(cleanedQuery)) {
        developmentStateContains.push(this);
      } else if (this.state_names && that.matchesAltName(this.state_names)) {
        developmentStateContains.push(this);
      } else if (this.region_names && that.matchesAltName(this.region_names)) {
        developmentStateContains.push(this);
      } else if (cleanedCountry.includes(cleanedQuery)) {
        developmentCountryContains.push(this);
      } else if (this.country_plain && this.country_plain.includes(cleanedQuery)) {
        developmentCountryContains.push(this);
      } else if (this.country_names && that.matchesAltName(this.country_names)) {
        developmentCountryContains.push(this);
      }
    });

    // Merge conatins into the end of developments to prioritize results that start with the query
    Array.prototype.push.apply(developments, contains);
    Array.prototype.push.apply(developments, developmentCityBegins);
    Array.prototype.push.apply(developments, developmentCityContains);
    Array.prototype.push.apply(developments, developmentStateContains);
    Array.prototype.push.apply(developments, developmentCountryContains);
    return developments;
  },

  destinationSuggestions: function() {
    var destinations = [];
    var contains = []
    var that = this;

    jQuery.each(this.optionList.destinations, function() {
      var cleanedName = this.name.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g,"");
      var cleanedQuery = that.q.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g,"");
      // Check if name _starts_ with query first then check for results that _contain_ query
      if (cleanedName.substring(0, cleanedQuery.length) == cleanedQuery) {
        destinations.push(this);
      } else if (cleanedName.includes(cleanedQuery)) { // Then check for names that _contain_ query
        contains.push(this);
      }
    });

    // Merge conatins into the end of destinations to prioritize results that start with the query
    Array.prototype.push.apply(destinations, contains);
    return destinations;
  },

  memberSuggestions: function() {
    var members = [];
    var memberCityBegins = [];
    var memberCityContains = [];
    var memberCountryContains = [];
    var memberNameBegins = [];
    var memberNameContains = [];
    var memberOfficeCityBegins = [];
    var memberOfficeCityContains = [];
    var memberStateContains = [];
    var regentCityBegins = [];
    var regentCityContains = [];
    var regentCountryContains = [];
    var regentNameContains = [];
    var regentOfficeCityBegins = [];
    var regentOfficeCityContains = [];
    var regentStateContains = [];
    var regentTerritoryBegins = [];
    var regentTerritoryContains = [];
    var that = this;

    jQuery.each(this.optionList.members, function() {
      var cleanedName = this.name.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedCity = this.city && this.city.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedMetro = this.metro_area && this.metro_area.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedRT = this.regent_territories.toLowerCase().replace(/[.\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedOffices = this.offices.toLowerCase().replace(/[.\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      var cleanedState = this.state && typeof(I18n.t("iso3166." + this.state)) !== 'undefined' ? I18n.t("iso3166." + this.state).toLowerCase().replace(/[.\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";
      var cleanedRegion = this.region && typeof(I18n.t("iso3166." + this.region)) !== 'undefined' ? I18n.t("iso3166." + this.region).toLowerCase().replace(/[.\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";
      var cleanedCountry = this.country && typeof(I18n.t("country_codes." + this.country)) !== 'undefined' ? I18n.t("country_codes." + this.country).toLowerCase().replace(/[.\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"") : "";
      var cleanedQuery = that.q.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'\s]/g,"");
      // Check if name _starts_ with query first then check for results that _contain_ query
      this.includeQuery = true;

      if (cleanedName.substring(0, cleanedQuery.length) == cleanedQuery) {
        this.includeQuery = false;
        if (this.regent) {
          members.push(this);
        } else {
          memberNameBegins.push(this);
        }
      } else if (cleanedName.includes(cleanedQuery)) { // Then check for names that _contain_ query
        this.includeQuery = false;
        if (this.regent) {
          regentNameContains.push(this);
        } else {
          memberNameContains.push(this);
        }
      } else if (this.city && cleanedCity.substring(0, cleanedQuery.length) == cleanedQuery) {
        if (this.regent) {
          regentCityBegins.push(this);
        } else {
          memberCityBegins.push(this);
        }
      } else if (this.metro_area && cleanedMetro.substring(0, cleanedQuery.length) == cleanedQuery) {
        if (this.regent) {
          regentCityBegins.push(this);
        } else {
          memberCityBegins.push(this);
        }
      } else if (this.city && cleanedCity.includes(cleanedQuery)) {
        if (this.regent) {
          regentCityContains.push(this);
        } else {
          memberCityContains.push(this);
        }
      } else if (this.metro_area && cleanedMetro.includes(cleanedQuery)) {
        if (this.regent) {
          regentCityContains.push(this);
        } else {
          memberCityContains.push(this);
        }
      } else if (cleanedRT.includes(cleanedQuery)) {
        var territory_begins = false;
        var territories = cleanedRT.split(',');
        for(var i = 0; i < territories.length; i++) {
          if((territories[i].substring(0, cleanedQuery.length) == cleanedQuery)) {
            regentTerritoryBegins.push(this);
            territory_begins = true;
            break;
          }
        }
        if(!territory_begins) {
          regentTerritoryContains.push(this);
        }
      } else if (cleanedState.includes(cleanedQuery) || cleanedRegion.includes(cleanedQuery)) {
        if (this.regent) {
          regentStateContains.push(this);
        } else {
          memberStateContains.push(this);
        }
      } else if (this.state_plain && this.state_plain.includes(cleanedQuery)) {
        if (this.regent) {
          regentStateContains.push(this);
        } else {
          memberStateContains.push(this);
        }
      } else if (this.state_names && that.matchesAltName(this.state_names)) {
        if (this.regent) {
          regentStateContains.push(this);
        } else {
          memberStateContains.push(this);
        }
      } else if (cleanedOffices.includes(cleanedQuery)) {
        var cities = cleanedOffices.split(',');
        var office_begins = false;
        for(var i = 0; i < cities.length; i++) {
          if((cities[i].substring(0, cleanedQuery.length) == cleanedQuery)) {
            if (this.regent) {
              regentOfficeCityBegins.push(this);
            } else {
              memberOfficeCityBegins.push(this);
            }
            office_begins = true
            break;
          }
        }
        if(!office_begins) {
          if (this.regent) {
            regentOfficeCityContains.push(this);
          } else {
            memberOfficeCityContains.push(this);
          }
        }
      } else if (this.region_names && that.matchesAltName(this.region_names)) {
        if (this.regent) {
          regentStateContains.push(this);
        } else {
          memberStateContains.push(this);
        }
      } else if (cleanedCountry.includes(cleanedQuery)) {
        if (this.regent) {
          regentCountryContains.push(this);
        } else {
          memberCountryContains.push(this);
        }
      } else if (this.country_plain && this.country_plain.includes(cleanedQuery)) {
        if (this.regent) {
          regentCountryContains.push(this);
        } else {
          memberCountryContains.push(this);
        }
      } else if (this.country_names && that.matchesAltName(this.country_names)) {
        if (this.regent) {
          regentCountryContains.push(this);
        } else {
          memberCountryContains.push(this);
        }
      }
    });

    // Merge contains into the end of members to prioritize results that start with the query
    Array.prototype.push.apply(members, regentNameContains);
    Array.prototype.push.apply(members, regentTerritoryBegins);
    Array.prototype.push.apply(members, regentCityBegins);
    Array.prototype.push.apply(members, regentStateContains);
    Array.prototype.push.apply(members, regentOfficeCityBegins);
    Array.prototype.push.apply(members, memberNameBegins);
    Array.prototype.push.apply(members, memberNameContains);
    Array.prototype.push.apply(members, regentTerritoryContains);
    Array.prototype.push.apply(members, memberCityBegins);
    Array.prototype.push.apply(members, regentCityContains);
    Array.prototype.push.apply(members, regentOfficeCityContains);
    Array.prototype.push.apply(members, memberCityContains);
    Array.prototype.push.apply(members, memberOfficeCityBegins);
    Array.prototype.push.apply(members, memberOfficeCityContains);
    Array.prototype.push.apply(members, memberStateContains);
    Array.prototype.push.apply(members, regentCountryContains);
    Array.prototype.push.apply(members, memberCountryContains);
    return members;
  },

  agentSuggestions: function() {
    var agents = [];
    var begins = [];
    var containsRegents = []
    var contains = []
    var that = this;

    jQuery.each(this.optionList.agents, function() {
      var cleanedName = this.name.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g,"");
      var cleanedQuery = that.q.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g,"");
      // Check if name _starts_ with query first then check for results that _contain_ query
      if (cleanedName.substring(0, cleanedQuery.length) == cleanedQuery) {
        if(this.regent) {
          agents.push(this);
        } else {
          begins.push(this);
        }
      } else if (cleanedName.includes(cleanedQuery)) { // Then check for names that _contain_ query
        if(this.regent) {
          containsRegents.push(this);
        } else {
          contains.push(this);
        }
      }
    });

    // Merge conatins into the end of agents to prioritize results that start with the query
    Array.prototype.push.apply(agents, containsRegents);
    Array.prototype.push.apply(agents, begins);
    Array.prototype.push.apply(agents, contains);
    return agents;
  },

  keywordSuggestion: function() {
    var excapeQuotes = this.q.replace(/[']/g, "%27");
    var keywordSearchItem = "<li class='category-heading moveLi keyword'><a class='move' href='" + this.searchUrl + "?q=" + excapeQuotes + "'><i class='fas fa-search'></i>\""  + this.q + "\" Keyword Search</a></li>";
    return keywordSearchItem;
  },

  // Convert suggestion list to html
  domlist: function() {
    var lis = [];
    var url = this.searchUrl;
    var that = this;
    var agents = [];
    var members = [];
    var places = [];
    var developments = [];
    var destinations = [];

    var that = this;

    lis.push(this.keywordSuggestion());

    [].forEach.call(this.agentSuggestions(), function(agent, index, arr) {
      var c_name = agent.name
      if(typeof(agent.city) !== 'undefined') {
        c_name += ', ' + agent.city;
      }
      if(typeof(agent.state) !== 'undefined') {
        c_name += ', '
        if(I18n.t("us_states." + agent.state)) {
          c_name += I18n.t("us_states." + agent.state);
        } else if(I18n.t("iso3166." + agent.state)) {
          c_name += (I18n.t("iso3166." + agent.state));
        }
      }
      if(typeof(agent.country) !== 'undefined') {
        c_name += ', ' + I18n.t("country_codes." + agent.country);
      }
      var href = that.searchUrl + "?profile_id=" + agent.profile_id + "&profile_name=" + encodeURIComponent(c_name).replace(/'/g, '&#39;');
      var li = "<li class='moveLi'><a class='move' href='" + href + "'>" + c_name + "</a></li>";
      agents.push(li);
    });

    [].forEach.call(this.memberSuggestions(), function(member, index, arr) {
      var query = member.includeQuery ? '&q=' + that.q : '';
      var href = that.searchUrl + "?profile_id=" + member.profile_id + "&profile_name=" + encodeURIComponent(member.name).replace(/'/g, '&#39;') + query;
      var li = "<li class='moveLi'><a class='move' href='" + href + "'>" + member.name + "</a></li>";
      members.push(li);
    });

    [].forEach.call(this.placeSuggestions(), function(place, index, arr) {
      var href = [];
      var text = '';
      var showStatesIn = ['US','CA','AU'];
      if(typeof(place.city) !== 'undefined') {
        href.push('city=' + place.city.replace(/[`~!@#$%^&*_|=?;:",<>\{\}\[\]]/g, '').replace(/'/g, '&#39;').replace(/ /g, '+'));
        text += place.city.titleCase() + ', ';
      }
      if(typeof(place.state) !== 'undefined' && (typeof(place.city) === 'undefined' || showStatesIn.includes(place.country))) {
        href.push('area=' + place.state);
        if(typeof(place.city) === 'undefined' && typeof(place.fullState) !== 'undefined') {
          text += place.fullState + ', ';
        } else {
          text += place.state.substr(3, place.state.length) + ', ';
        }
      } else if(typeof(place.country) !== 'undefined') {
        href.push('area=' + place.country);
      }
      if(typeof(place.country) !== 'undefined') {
        text += place.countryI18n;
      }
      var li = "<li class='moveLi'><a class='move' href='" + url + '?' + href.join('&') + "'>" + text + "</a></li>";
      places.push(li);
    });

    [].forEach.call(this.developmentSuggestions(), function(development, index, arr) {
      var c_name = development.name
      var showStatesCode = ['US','CA','AU'];
      var stateName = I18n.t("iso3166." + development.state);
      if(development.city && typeof(development.city) !== 'undefined') {
        c_name += ', ' + development.city;
      }
      if(development.state && typeof(development.state) !== 'undefined') {
        if(showStatesCode.includes(development.country)) {
          c_name += ', ' + development.state.substr(3, development.state.length);
        } else if(typeof(stateName) !== 'undefined') {
          c_name += ', ' + stateName;
        }
      }
      if(development.country && (typeof(development.country) !== 'undefined')) {
        c_name += ', ' + I18n.t("country_codes." + development.country);
      }
      var li = "<li class='moveLi'><a class='move' href='" + that.searchUrl + "?development_id=" + development.development_id + "&profile_name=" + encodeURIComponent(c_name).replace(/'/g, '&#39;') + "'>" + c_name + "</a></li>";
      developments.push(li);
    });

    [].forEach.call(this.destinationSuggestions(), function(destination, index, arr) {
      var d_name = destination.name
      var search = []
      if(destination.id != null) { search.push("destination_id=" + destination.id); }
      if(destination.name != null) { search.push("destination_name=" + customEncodeURIComponent(destination.name)); }
      if(destination.area != null) { search.push("area=" + destination.area); }
      if(destination.city != null) { search.push("city=" + customEncodeURIComponent(destination.city)); }
      if(destination.map_bounds != null) { search.push("map_bounds=" + destination.map_bounds); }
      if(destination.q != null) { search.push("q=" + destination.q); }

      var li = "<li class='moveLi'><a class='move' href='" + that.searchUrl + "?" + search.join('&') + "'>" + d_name + "</a></li>";
      destinations.push(li);
    });

    var professional_search = (this.searchUrl.includes('professionals'));
    var memberHeader = "<li class='category-heading'><i class='icon-lre'></i>Suggested Members</li>";

    members = jQuery.unique(members).slice(0,5);

    if(professional_search) {
      if (agents.length > 0) {
        var agentHeader = "<li class='category-heading'><i class='fas fa-user-circle'></i>Suggested Associates</li>";
        lis.push(agentHeader);
        agents = jQuery.unique(agents);
        agents = agents.slice(0,5);
        lis = lis.concat(agents);
      }
    }

    if (places.length > 0) {
      var placeHeader = "<li class='category-heading'><i class='far fa-map-marker'></i>Search in suggested places</li>";
      lis.push(placeHeader);
      places = jQuery.unique(places);
      places = places.slice(0,5);
      lis = lis.concat(places);
    }

    if(!professional_search) {
      if (developments.length > 0) {
        var developmentHeader = "<li class='category-heading'><i class='fas fa-home'></i>Suggested Developments</li>";
        lis.push(developmentHeader);
        developments = jQuery.unique(developments);
        developments = developments.slice(0,5);
        lis = lis.concat(developments);
      }

      if (destinations.length > 0) {
        var destinationHeader = "<li class='category-heading'><i class='fal fa-globe'></i>Suggested Destinations</li>";
        lis.push(destinationHeader);
        destinations = jQuery.unique(destinations);
        destinations = destinations.slice(0,5);
        lis = lis.concat(destinations);
      }
    }
    if (members.length > 0) {
      lis.push(memberHeader);
      lis = lis.concat(members);
    }

    return lis;
  },

  // Get unique suggestions
  unique: function() {
    var unique = this.domlist().filter(function(itm, i, a) {
      return i == a.indexOf(itm);
    });
    return unique;
  }
}

function displaySuggestions(suggestionsObj, input, optionList, searchUrl) {
  var qInput = document.querySelector(input);
  var searchQuery = qInput.value.replace(/,/g, '');
  var results = new getSuggestions(searchQuery, optionList, searchUrl);
  var unique = results.unique();
  
  var formattedResults =[];
  var resultsCount = 10;
  // window.innerWidth < 800
  if(jQuery(window).width() < 800) {
    resultsCount = 7;
  }

  if(unique.length > 0) {
    formattedResults.push(unique);
  }

  // // Highlight default result
  if (formattedResults[0][2]) {
    var resultText = formattedResults[0][2].replace(/<\/?[^>]+(>|$)/g, "");
    var doKeyWordSearch = false;
    var resultMatches = formattedResults[0].filter(result => !result.includes("category-heading"));
    if (resultMatches.length > 1) {
      doKeyWordSearch = resultMatches.every(result => {
        return result.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase().includes(searchQuery.toLowerCase() );
      });
    }

    if (!doKeyWordSearch && resultText.toLowerCase().includes(searchQuery.toLowerCase() )) {
      formattedResults[0][2] = formattedResults[0][2].replace('moveLi', 'moveLi initial');
    } else {
      formattedResults[0][0] = formattedResults[0][0].replace('moveLi', 'moveLi selectedLi');
    }
  }

  if(!formattedResults[0]) {
    closeSuggestions(input, suggestionsObj)
  } else if(formattedResults[0].length > resultsCount) {
    formattedResults = formattedResults[0].slice(0,resultsCount).join('');
  } else {
    formattedResults = formattedResults[0].join('');
  }
  document.querySelector(suggestionsObj +' ul').innerHTML = formattedResults;
}

function nextSuggestion(suggestionsObj, input) {
  var $selectable = jQuery(suggestionsObj).find('.move');
  var count = $selectable.length;
  var index = 0;

  clearInitialHighlight();
  for(var i = 0; i < count; i++) {
    if($selectable[i].getAttribute('aria-selected') === 'true') {
      $selectable[i].setAttribute('aria-selected', 'false');
      jQuery($selectable[i]).removeClass('selected').parent('li').removeClass('selectedLi');
      index = i + 1;
    }
  }

  if(index > count) {
    index = 0;
  }

  goToSuggestion(index, input);
}

// Get index for previous object with class 'move'
function previousSuggestion(suggestionsObj, input) {
  var $selectable = jQuery(suggestionsObj).find('.move');
  var count = $selectable.length;
  var index = count - 1;

  clearInitialHighlight();
  for(var i = 0; i < count; i++) {
    if($selectable[i].getAttribute('aria-selected') === 'true') {
      $selectable[i].setAttribute('aria-selected', 'false');
      jQuery($selectable[i]).removeClass('selected').parent('li').removeClass('selectedLi');
      index = i - 1;
    }
  }

  if(index === -1) {
    index = count;
  }

  goToSuggestion(index, input);
}

// Select next/previous object with class 'move'
function goToSuggestion(index, input) {
  var selectable = jQuery('.keyword-group').find('.move');
  var count = selectable.length;

  jQuery(input).data('url', '');

  if(selectable[index]) {
    selectable[index].setAttribute('aria-selected', 'true');
    jQuery(selectable[index]).addClass('selected').parent('li').addClass('selectedLi');
    jQuery(input).val(jQuery(selectable[index]).text());
    jQuery(input).data('url', jQuery(selectable[index]).prop('href'));
  }
}

// Unrender suggestions
function closeSuggestions(input, suggestionsObj) {
  jQuery(input).removeData();
  document.querySelector(suggestionsObj +' ul').innerHTML = '';
}

function clearInitialHighlight() {
  jQuery('#predictive-search li.moveLi.initial').removeClass('initial');
}

function customEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/'/g, "%27");
}
