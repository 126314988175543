import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["showLinks"]

  showHideLinks() {
    var hiddenLinkSections = document.querySelectorAll('.hidden-link');
    [].forEach.call(hiddenLinkSections, function(section) {
      section.classList.toggle('d-none');
      section.classList.toggle('d-flex');
      var links = section.querySelectorAll('a[data-src]');
      [].forEach.call(links, function(link) {
         link.href = link.dataset.src;
      });
    });
    this.updateShowHideButtonText();
  }

  updateShowHideButtonText() {
    var showText = this.data.get("showText");
    var hideText = this.data.get("hideText");
    var showHideButton = this.showLinksTarget;
    if(showHideButton.innerText.includes(showText)) {
      showHideButton.innerText = hideText;
    } else {
      showHideButton.innerText = showText;
    }
  }

}
