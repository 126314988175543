import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["favoriteButton"]

  toggle() {
    var that = this;
    var url = this.data.get("addUrl");
    var requestType = 'post';

    this.toggleSpinner();

    if (this.isFavorited()) {
      url = this.data.get("removeUrl");
      requestType = 'delete';
    }

    Rails.ajax({
      type: requestType,
      url: url,
      success: function(response) {
        that.updateFavorited();
        that.updateIcon();
        that.updateText();
        that.trackFavorite();
        that.toggleSpinner();
      },
      error: function(response) {
        console.log('ajax error: ' + response);
      }
    });
  }

  toggleSpinner() {
    var icon = document.getElementById('favorite-icon-' + this.data.get('id'));
    var spinner = document.getElementById('favorite-spinner-' + this.data.get('id'));
    icon.classList.toggle('d-none');
    spinner.classList.toggle('d-none');
  }

  isFavorited() {
    var isFavorited = this.data.get("favorited") == 'true' ? true : false;
    return isFavorited;
  }

  updateFavorited() {
    if (this.isFavorited()) {
      this.data.set("favorited", "false");
    } else {
      this.data.set("favorited", "true");
    }
  }

  updateIcon() {
    var icon = document.getElementById('favorite-icon-' + this.data.get('id'));
    icon.classList.toggle('fa-heart');
    icon.classList.toggle('fa-trash');
  }

  updateText() {
    var textSpan = document.getElementById('favorite-text-' + this.data.get('id'));
    if (textSpan) {
      var text = this.isFavorited() ? this.data.get("removeText") : this.data.get("addText");
      textSpan.innerText = text;
      jQuery('#favorite-button').tooltip('dispose');
      jQuery('#favorite-button').tooltip({title: text});
    }
  }

  trackFavorite() {
    var etjsElement = document.getElementById('et');
    var analyticsController = this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics');
    var itemDetails = this.data.get('item');
    if (this.isFavorited()) {
      analyticsController.trackEvent({
        'name': 'favorite',
        'action': 'save',
        'label': itemDetails
      });
    } else {
      analyticsController.trackEvent({
        'name': 'favorite',
        'action': 'remove',
        'label': itemDetails
      });
    }
  }

}
