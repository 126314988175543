import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['pageFrame']

  static values = {
    index: Number,
    pageCount: String,
    baseUrl: String
  }

  pageFrameTargetConnected(element) {
    var swiper = document.getElementById('swiper').swiper;
    var index = this.indexValue;
    var baseUrl = this.baseUrlValue;
    var pageCount = this.pageCountValue;
    var pageFrame = document.getElementById('page-content');
    var breadcrumbFrame = document.getElementById('breadcrumb-nav');

    swiper.slideTo(this.indexValue, 300);

    swiper.on('beforeTransitionStart', function(e, speed, internal) {
      var newIndex;
      var activeIndex = e.activeIndex;

      if (activeIndex > pageCount) {
        newIndex = activeIndex - pageCount;
      } else if (activeIndex <= 0) {
        newIndex = pageCount;
      } else {
        newIndex = activeIndex;
      }

      var newURL = baseUrl + newIndex;

      pageFrame.src = newURL;
      breadcrumbFrame.src = newURL;
      updateURL(newURL);

    });

  }

}

function updateURL(url) {
  history.replaceState(null, '', url);
  window.onpopstate = function(event) {
    Turbo.visit(document.location);
  }
}
