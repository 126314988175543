import { Controller } from '@hotwired/stimulus'
import * as noUiSlider from 'nouislider'

export default class extends Controller {

    connect() {
      setupRangeSliders();
    }
}

function setupRangeSliders() {
  var ranges = {
    priceRange: {
      'min': 0,
      '7%': 500000,
      '14%': 1000000,
      '21%': 1500000,
      '28%': 3000000,
      '35%': 5000000,
      '42%': 7500000,
      '49%': 10000000,
      '56%': 15000000,
      '63%': 20000000,
      '70%': 25000000,
      '77%': 50000000,
      '84%': 75000000,
      '91%': 100000000,
      'max': 150000000
    },
    rentalPriceRange: {
      'min': 0,
      '7%': 1000,
      '14%': 1750,
      '21%': 2500,
      '28%': 3000,
      '35%': 3750,
      '42%': 4500,
      '49%': 5000,
      '56%': 5750,
      '63%': 6500,
      '70%': 7500,
      '77%': 8250,
      '84%': 9000,
      '91%': 9500,
      'max': 10000
    },
    lotSizeRange: {
      'min': 0,
      '10%': 100,
      '20%': 200,
      '30%': 300,
      '40%': 400,
      '50%': 500,
      '60%': 600,
      '70%': 700,
      '80%': 800,
      '90%': 900,
      'max': 1000
    },
    default: {
      'min': 0,
      '10%': 1,
      '20%': 2,
      '30%': 3,
      '40%': 4,
      '50%': 5,
      '60%': 6,
      '70%': 7,
      '80%': 8,
      '90%': 9,
      'max': 10
    }
  }

  var rangeSliders = document.querySelectorAll('.range-slider:not(.noUi-target)');

  if (rangeSliders.length) {
    [].forEach.call(rangeSliders, function(slider) {


      // Setup range sliders
      var range = slider.getAttribute('data-range') ? ranges[slider.getAttribute('data-range')] : ranges['default'];
      var startMinValue = slider.getAttribute('data-startMin') < range.max ? slider.getAttribute('data-startMin') : null;
      var startMaxValue = slider.getAttribute('data-startMax');

      if (slider.noUiSlider) {
        slider.noUiSlider.reset();
      } else {
        noUiSlider.create(slider, {
          start: [startMinValue, startMaxValue],
          connect: true,
          step: parseInt(slider.getAttribute('data-step')) || null,
          snap: true,
          range: range
        });
      }

      // Display values
      var sliderFor = slider.getAttribute('for');
      var minValContainer = document.querySelector('.range-slider-values.' + sliderFor + '-min');
      var maxValContainer = document.querySelector('.range-slider-values.' + sliderFor + '-max');
      var valContainer = document.querySelector('.range-slider-values.' + sliderFor);

      slider.noUiSlider.on('update', function (vals, handle, unencoded, tap, positions, noUiSlider) {

        var minField = document.getElementById(sliderFor + '_min');
        var maxField = document.getElementById(sliderFor + '_max');

        var minValue = parseInt(vals[0]).toFixed(0) == range.min ? '' : parseInt(vals[0]).toFixed(0);
        var maxValue = parseInt(vals[1]).toFixed(0) == range.max ? '' : parseInt(vals[1]).toFixed(0);

        var isMax = vals[1] == range.max ? true : false;
        var plus = isMax ? '+' : '';
        var formattedMax = slider.getAttribute('data-format') == 'currency' ? formatCurrency(vals[1]) : parseInt(vals[1]).toFixed(0);
        var maxLabel = isMax ? 'Any' : formattedMax;

        if (slider.getAttribute('data-format') == 'currency') {
          if (vals[0] == vals[1]) {

            if (valContainer) {
              valContainer.innerText = maxLabel;
            } else if (minValContainer && maxValContainer) {
              minValContainer.innerText = '';
              maxValContainer.innerText = maxLabel;
            }

          } else {

            if (valContainer) {
              valContainer.innerText = formatCurrency(vals[0]) + ' - ' + maxLabel;
            } else if (minValContainer && maxValContainer) {
              minValContainer.innerText = formatCurrency(vals[0]);
              maxValContainer.innerText = maxLabel;
            }
          }
        } else {
          if (vals[0] == vals[1]) {

            if (valContainer) {
              valContainer.innerText = maxLabel;
            } else if (minValContainer && maxValContainer) {
              minValContainer.innerText = '';
              maxValContainer.innerText = maxLabel;
            }

          } else {
            if (valContainer) {
              valContainer.innerText = parseInt(vals[0]).toFixed(0) + ' - ' + maxLabel;
            } else if (minValContainer && maxValContainer) {
              minValContainer.innerText = parseInt(vals[0]).toFixed(0);
              maxValContainer.innerText = maxLabel;
            }
          }
        }

        minField.value = minValue;
        maxField.value = maxValue;
      });

      // Fire custom 'sliderSet' event for Stimulus
      slider.noUiSlider.on('set', function(values, handle, unencoded, tap, positions, noUiSlider) {
        var event = document.createEvent('Event');
        event.initEvent('sliderSet', true, true);
        noUiSlider.target.dispatchEvent(event);
      });

      document.addEventListener('turbo:before-cache', function(event) {
        if(slider.noUiSlider) {
          slider.noUiSlider.destroy();
        }
      });

    });
  }

}

function formatCurrency(number) {
  var decPlaces = 0; //isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  var decSep = '.'; // typeof decSep === "undefined" ? "." : decSep;
  var thouSep = ','; //typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? '-' : '';
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign + '$' + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
