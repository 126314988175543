import { Controller } from '@hotwired/stimulus'

var baseForm, visibleForm, hiddenForm, page;

export default class extends Controller {
  static targets = [ 'archivesMonthSelect', 'archivesYearSelect', 'archiveYear', 'monthsHeader', 'pageLink', 'searchToggleBtn', 'submitButton', 'keywordSidesearch', 'keywordCentersearch']

  initialize() {
    if (document.getElementById('news-index')) {
      baseForm = document.getElementById('base-form');
      page = 'index';
      setIndexForm();
      setIndexSearchMenu();
    } else if (document.getElementById('news-show')) {
      page = 'show';
      clearArchives();
    }
    if (location.search.includes('year')) {
      const urlParams = new URLSearchParams(window.location.search);
      var year = urlParams.get('year');
      this.populateMonthLinks(year);
      document.getElementById('months').classList.remove('d-none');
    }
  }

  getSearchQuery() {
    setIndexForm();
    var searchParams = [];
    baseForm = document.getElementById('base-form');
    var inputs = baseForm.getElementsByTagName('input');
    [].forEach.call(inputs, function(input) {
      if (input.id == 'base-news-q') {
        // if the visible form has a value, then set it to the baseForm
        if (document.getElementById(visibleForm.id + '-news-q').value != '') {
          input.value = document.getElementById(visibleForm.id + '-news-q').value;
          searchParams.push(input.name + '=' + input.value.replace(/ /g, '+'));
        } else {
          // just update baseForm
          input.value = '';
        }
      }
      else if (input.id == 'base-archives-year') { // check for year input first since the logic is a little different
        if (input.value != '') {
          searchParams.push(input.name + '=' + input.value);
        } else {
          clearArchives();
        }
      }
      else if (input.value != '') {
        searchParams.push(input.name + '=' + input.value);
      }

    });
    this.setValues();
    jQuery('select.selectpicker').selectpicker('refresh');
    var searchQuery = searchParams.join('&');
    return searchQuery;
  }

  setValues() {
    document.getElementById(hiddenForm.id + '-news-q').value = document.getElementById('base-news-q').value;

    var $select = jQuery(hiddenForm).find('select.archives-year');
    jQuery($select).selectpicker('val', document.getElementById('base-archives-year').value);

    var $select = jQuery(hiddenForm).find('select.archives-month');
    jQuery($select).selectpicker('val', document.getElementById('base-archives-month').value);
  }

  clickTag(e) { // determines check or uncheck
    if (page == 'show') {
      this.clickShowTag(e);
    } else {
      if (e.target.value == baseForm.querySelector('#base-tag').value) {
        e.preventDefault();
        this.uncheckTag(e.target);
      } else {
        this.checkTag(e.target);
      }
      this.submitFromIndex(e);
    }
  }

  checkTag(tag) {
    tag.checked = true;
    jQuery('input:checkbox').not(tag).prop('checked', false);
    baseForm.querySelector('#base-tag').value = tag.value;
  }

  uncheckTag(tag) {
    tag.checked = false;
    baseForm.querySelector('#base-tag').value = '';
  }

  fetchArchives(type, year, callback) {
    jQuery.getJSON(type + '/archives/' + year, callback);
  }

  buildIndexArchivesMonths(e) {
    var year = e.target;
    // next: determine if building months for form select or section list
    if (year.classList.contains('archive-link')) {
      this.buildMonthsList(e, year.text)
    } else if (year.classList.contains('archive-select')) {
      if (year.value == '') {
        clearArchives();
      } else {
        jQuery('.archives-year').selectpicker('val', year.value); //set hidden
        jQuery('.archives-month').selectpicker('val', '');//set hidden
        this.buildMonthsSelect(year.value);
        enableMonths(visibleForm);
      }
    } // submit after to search using year param
    this.submitFromIndex(e)
  }


  buildMonthsSelect(year) {
    var that = this;
    if (page == 'index') {
      baseForm.querySelector('input#base-archives-year').value = year;
      baseForm.querySelector('input#base-archives-month').value = '';
    }
    var type = getNewsType();
    this.fetchArchives(type, year, function(data) {
      var links = Object.values(data);
      that.buildOptions(data);
    });
  }

  buildOptions(links) {
    var $monthsSelects = jQuery('select.archives-month');
    jQuery.each($monthsSelects, function(index, select) {
      var emptyOp = jQuery(select).children()[1];
      jQuery(select).html('');
      jQuery(select).append(emptyOp);

      for (let linkObj of links) {
        var option = document.createElement('option');
        option.value = linkObj.link.month;
        option.innerText = linkObj.text;
        jQuery(select).append(option);
      }
      jQuery(select).selectpicker('refresh');

    });
  }

  buildMonthsList(e, year) {
    var that = this;
    var ogYear = year
    var archiveMonthsID = document.getElementById('months');
    archiveMonthsID.classList.remove('d-none');
    var header = document.querySelector('#months h3');
    header.textContent = year + ' Archives';
    var archiveMonthsClass = document.querySelector('.month-archives');
    archiveMonthsClass.innerHTML = '';

    var type = getNewsType();
    this.fetchArchives(type, year, function(data) {
      var links = Object.values(data);
      var baseForm = document.getElementById('base-form');
      for (let linkObj of links) {
        var monthLink = document.createElement('a');
        monthLink.className = 'recent';
        monthLink.innerText = linkObj.text;
        monthLink.dataset.year = linkObj.link.year;
        monthLink.dataset.month = linkObj.link.month;
        monthLink.href =  '#';
        monthLink.addEventListener('click', function(event) {
          event.preventDefault();
          var year = event.target.dataset.year;
          var month = event.target.dataset.month;
          baseForm.querySelector('input#base-archives-year').value = year;
          baseForm.querySelector('input#base-archives-month').value = month;
          that.buildOptions(links);
          jQuery('select.archives-year').selectpicker('val', year);
          jQuery('select.archives-month').selectpicker('val', month);
          jQuery('select.selectpicker').selectpicker('refresh');
          if ( location.pathname.match(/\d{1,}/)) {
            that.submitFromShow(event);
          } else {
            that.submitFromIndex(event, year, month);
          }
          updateWiRHeadings(year, month);
        });
        if (archiveMonthsClass.childElementCount < links.length) {
          archiveMonthsClass.appendChild(monthLink);
        }
      }
    });
    updateWiRHeadings(ogYear);
  }

  submitArchives(e) {
    if (page == 'index') {
      var month = e.target.value;
      baseForm.querySelector('input#base-archives-month').value = month;
      this.submitFromIndex(e);
    } else if (page == 'show') {
      this.submitFromShow(e);
    }
  }

  paginate(event) {
    event.preventDefault();
    this.getPosts(this.data.get('url'));
    this.trackSearchResultsPageviews();
  }

  trackSearchResultsPageviews() {
    var etjsElement = document.getElementById('et');
    var analyticsController = this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics');
    analyticsController.trackPage();
  }

  submitFromIndex(e, year = null, month = null) {
    e.preventDefault();
    var query = this.getSearchQuery();
    var baseURL = location.pathname;
    var url = baseURL + '?' + query;
    this.getPosts(url, year, month);
  }

  getPosts(url, year = null, month = null) {
    var that = this;
    Rails.ajax({
      type: 'get',
      url: url,
      success: function(response) {
        that.updateResults(response);
        that.updateURL(url);
        updateWiRHeadings(year, month);
      },
      error: function(response) {
        console.log('ajax error: ' + response);
      }
    });
  }

  updateResults(results) {
    var el = document.getElementById('posts');
    el.innerHTML = results;
  }

  updateURL(url) {
    history.replaceState(null, '', url);
    window.onpopstate = function(event) {
      Turbo.visit(document.location)
    }
  }

  toggleSearchMenu() {
    var searchToggle = this.searchToggleBtnTarget;
    searchToggle.classList.toggle('selected');
    document.getElementById('centerSearch').classList.toggle('d-none');
    document.querySelector('.news-nav').classList.toggle('search-open');
  }

  //////////////////////////////NEWS SHOW ACTIONS/////////////////////////////////////////////////////////////////

  updateNewsSearchYear(e) {
    e.preventDefault();
    var year = this.data.get("year");
    var page = this.data.get("page");
    var url = location.origin + location.pathname + '?year=' + year
    this.buildMonthsList(e, year, false);
    if (page == 'index') {
      this.getPosts(url, year, null);
    }
  }

  populateMonthLinks(year) {
    // Todo - update year links to update url
    var type = getNewsType();
    var archiveMonthsClass = document.querySelector('.month-archives');

    this.fetchArchives(type, year, function(data) {
      var links = Object.values(data);
      if (links.length > 0) {
        var monthsEl = document.getElementById('months');
        monthsEl.classList.remove('d-none');
      }
      for (let linkObj of links) {
        var monthLink = document.createElement('a');
        monthLink.className = 'recent';
        monthLink.innerText =  linkObj.text;
        monthLink.href = location.origin + type + '?year=' + linkObj.link.year + '&month=' + linkObj.link.month;
        if (archiveMonthsClass.childElementCount < links.length) {
          archiveMonthsClass.appendChild(monthLink);
        }
      }
    });
  }

  buildShowArchivesMonths(e) {
    var year = e.target;
    if (year.value == '') {
      clearArchives();
    } else {
      jQuery('.archives-year').selectpicker('val', year.value);
      jQuery('.archives-month').selectpicker('val', '');
      this.buildMonthsSelect(year.value);
      var showForm = document.getElementById('sideSearch');
      enableMonths(showForm);
    }
  }

  buildShowMonthsList(e) {
    e.preventDefault();
    var year = e.target.text;
    var archiveMonthsID = document.getElementById('months');
    archiveMonthsID.classList.remove('d-none');

    var header = this.monthsHeaderTarget.textContent = year + ' Archives';
    var archiveMonthsClass = document.querySelector('.month-archives');
    archiveMonthsClass.innerHTML = '';
    var q = document.getElementById('sideSearch-news-q').value;
    var type = getNewsType();
    this.fetchArchives(type, year, function(data) {
      var links = Object.values(data);
      for (let linkObj of links) {
        var monthLink = document.createElement('a');
        monthLink.className = 'recent';
        monthLink.innerText =  linkObj.text;
        if (q != '') {
          monthLink.href = location.origin + type + '?q=' + q + '&year=' + linkObj.link.year + '&month=' + linkObj.link.month;
        } else {
          monthLink.href = location.origin + type + '?year=' + linkObj.link.year + '&month=' + linkObj.link.month;
        }
        if (archiveMonthsClass.childElementCount < links.length) {
          archiveMonthsClass.appendChild(monthLink);
        }
      }
    });
  }

  clickShowTag(e) {
    e.preventDefault();
    e.target.checked = true;
    jQuery('#tag-cloud input:checkbox').not(e.target).prop('checked', false);
    this.submitFromShow(e);
  }

  submitFromShow(e) {
    e.preventDefault();
    var query = [];
    if (document.getElementById('sideSearch-news-q').value) {
      query.push('q=' + document.getElementById('sideSearch-news-q').value);
    }
    if (jQuery('select.archives-year').val()) {
      query.push('year=' + jQuery('select.archives-year').val());
    }
    if (jQuery('select.archives-month').val()) {
      query.push('month=' + jQuery('select.archives-month').val());
    }
    if (document.getElementById('tag-cloud')) {
      if (jQuery('#tag-cloud input:checkbox:checked').length > 0) {
        query.push('tag_name=' + jQuery('#tag-cloud input:checkbox:checked').val());
      }
    }
    var baseURL = location.origin + getNewsType();
    var url = baseURL + '?' + query.join('&');
    this.visit(url);
  }

  visit(url) {
    window.location.href = url;
  }
 /////////////////////////////////////////////////////////////////////////////////////////////////////////
}

window.addEventListener('resize', function() {
  setIndexForm();
  setIndexSearchMenu();
});

function getNewsType() {
  if (window.location.pathname.includes('blog')) {
    return '/blog';
  } else if (window.location.pathname.includes('press_releases')) {
    return '/news/press_releases';
  } else if (window.location.pathname.includes('week_in_review')) {
    return '/news/week_in_review';
  }
}


function setIndexForm() {
  if (getNewsType() == '/news/week_in_review') {
    visibleForm = document.getElementById('sideSearch');
    hiddenForm = visibleForm;
  } else {
    if (window.innerWidth > 768) {
      visibleForm = document.getElementById('sideSearch');
      hiddenForm = document.getElementById('centerSearch');
    } else {
      hiddenForm = document.getElementById('sideSearch');
      visibleForm = document.getElementById('centerSearch');
    }
  }
}

function setIndexSearchMenu() {
  var searchToggle = document.getElementById('search-toggle');
  var centerSearch = document.getElementById('centerSearch');
  var newsSideSearch = document.getElementById('sideSearch');
  var newsNav = document.querySelector('.news-nav');

  if (searchToggle) {
    if (window.innerWidth >= 768) {
      searchToggle.classList.add('d-none');
      centerSearch.classList.add('d-none');
      enableMonths(newsSideSearch);

      newsNav.classList.remove('search-open');
      document.querySelector('.posts-row').classList.remove('search-open');
    } else {
      searchToggle.classList.remove('d-none');
      centerSearch.classList.remove('d-none');
      enableMonths(centerSearch);

      if (newsNav.classList.contains('search-open')) {
        centerSearch.classList.remove('d-none');
      } else {
        centerSearch.classList.add('d-none');
      }
    }
  }
}

function enableMonths(givenForm) {
  var $yearSelect = jQuery(givenForm).find('select.archives-year');
  if ($yearSelect.val() != '') {
    jQuery(givenForm).find('select.archives-month').removeAttr('disabled');
  } else {
    jQuery(givenForm).find('select.archives-month').attr('disabled', true);
  }
  jQuery('.selectpicker').selectpicker('refresh');
}

function clearArchives() {
  if (page == 'index') {
    baseForm.querySelector('input#base-archives-year').value = '';
    baseForm.querySelector('input#base-archives-month').value = '';
  }

  var $selects = jQuery('select.selectpicker');
  jQuery.each($selects, function(index, select) {
    jQuery(select).selectpicker('val', '');
    if (select.classList.contains('archives-month')){
      jQuery(select).attr('disabled', true);
    }
  });
}

function updateWiRHeadings(year = null, month = null) {

  if (getNewsType() == '/news/week_in_review' && (year || month) && page == 'index') {
    var period = searchPeriod(year, month);

    var sectionMetadataHeading = document.getElementById('section-metadata');
    var previousIssuesHeading = document.getElementById('previous-issues');


    sectionMetadataHeading.textContent = 'Showing results for ' + period;
    if (previousIssuesHeading) {
      previousIssuesHeading.textContent = period + ' Issues';
    }
  }

}

function searchPeriod(year = null, month = null) {
  var searchPeriod = '';
  var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

  if (month != null) {
    searchPeriod += months[parseInt(month) - 1] + ' ';
  }

  if (year != null) {
    searchPeriod += year;
  }

  return searchPeriod;
}
