// Import Firebase
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, onSnapshot, query, where, orderBy, limit} from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDsMDw5I02-SHBPLhyinMvgodo_wn9Qbg",
  authDomain: "luxre-d8ff9.firebaseapp.com",
  projectId: "luxre-d8ff9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

var featuredObjectUnsubscribe;
var recentEditorialUnsubscribe;
var profileFeatureUnsubscribe;
var editorialPayloadCounter = 0;
var profileFeaturedListingPayloadCounter = 0;
var profileFeaturedAssociatePayloadCounter = 0;

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['homepageSmall', 'homepageProfessionals', 'recentContentPosts', 'recentBlogPosts', 'addedPost', 'homepageDestinations']

  static values = {
    environment: String,
    account: String
  }

  updateHomepageSmall(data, limit = 4) {
    const element = this.homepageSmallTarget;
    const items = data.slice(0, limit);

    element.innerHTML = "";

    items.forEach((item) => {
      var newSmallFeature = `<div class="small-feature col-sm-6 col-md-lg-3">
        <div class="img-zoom img rounded">
          <a href="${item.targetURL}"><img
              srcset="${item.imageURL} 1x, ${item.imageURL} 2x"
              class="card-img" alt="The Destination On Charleston ..."
              src="${item.imageURL}"></a>
          <span class="sr-only">${item.name}</span>
        </div>
        <div class="d-flex justify-content-between">
          <p class="title">${item.formattedPrice}</p>
        </div>
        <p class="text-truncate">${item.accountName}</p>
        <p class="text-truncate">${item.formattedLocation}</p>
      </div>`;

      element.innerHTML += newSmallFeature;
    });
  }

  updateHomepageProfessionals(data, limit = 6) {
    const element = this.homepageProfessionalsTarget;
    const items = data.slice(0, limit);

    element.innerHTML = "";

    items.forEach((item) => {
      var regent = item.isRegent ? 'regent' : '';
      var colClass = card_col_class(items.length);

      var newCard = `<div class="small-feature col-sm-6 col-md-4 ${colClass}">
        <div class="card card-profile ${regent}">
          <a class="img profile-link" href="${item.targetURL}"><img alt="${item.name}" class="card-img-top"
              src="${item.imageURL}">
          </a>
          <div class="card-body">
            <a class="stretched-link profile-link" href="${item.targetURL}">
              <p class="name card-title">${item.name}</p>
            </a>
            <p class="account card-text">${item.accountName}</p>
            <p class="location card-text text-muted">${item.formattedLocation}</p>
          </div>
        </div>
      </div>`;

      element.innerHTML += newCard;
    });
  }

  addRecentBlogPost(post) {
    const postExists = document.getElementById('blog-post-' + post.id) || false;

    if (!postExists) {
      const element = this.recentBlogPostsTarget;
      const newPost = this.postTemplate(post);
      element.insertAdjacentHTML('afterbegin', newPost);
    }

  }

  updateRecentPost(post) {
    const type = post.context == 'recentContentPost' ? 'content-post' : 'blog-post';
    const element = document.getElementById(type + '-' + post.id);
    const newPost = this.postTemplate(post, false);

    element.innerHTML = newPost;
  }

  removeRecentPost(post) {
    const type = post.context == 'recentContentPost' ? 'content-post' : 'blog-post';
    const element = document.getElementById(type + '-' + post.id);
    const elementHeight = element.scrollHeight + 'px';

    element.style.height = elementHeight;
    element.classList.add('removed-post');
    setTimeout(() => {
      element.style.height = null;
    }, 1000);
  }

  addRecentContentPost(post) {
    const postExists = document.getElementById('content-post-' + post.id) || false;

    if (!postExists) {
      const element = this.recentContentPostsTarget;
      const newPost = this.postTemplate(post);
      element.insertAdjacentHTML('afterbegin', newPost);
    }
  }

  updateRecentBlogPosts(data, limit = 5) {
    const element = this.recentBlogPostsTarget;
    const items = data.slice(0, limit);

    element.innerHTML = "";

    items.forEach((item) => {
      var newPost = `<div id="blog-post-${item.id}" class="post-link-group d-flex justify-content-start">
        <div class="link-group ml-2">
          <a class="title" href="${item.targetURL}">${item.title}</a>
          <p class="postdata date text-muted">
            <i class="far fa-clock"></i>
            ${item.subtitle}
          </p>
        </div>
      </div>`;

      element.innerHTML += newPost;
    });

  }

  updateRecentContentPosts(data, limit = 5) {
    const element = this.recentContentPostsTarget;
    const items = data.slice(0, limit);

    element.innerHTML = "";

    items.forEach((item) => {
      var newPost = `<div id="content-post-${item.id}" class="post-link-group d-flex justify-content-start">
        <div class="link-group ml-2">
          <a class="title" href="${item.targetURL}">${item.title}</a>
          <p class="postdata date text-muted">
            <i class="far fa-clock"></i>
            ${item.subtitle}
          </p>
        </div>
      </div>`;

      element.innerHTML += newPost;
    });

  }

  updateHomepageDestinations(data, limit = 4) {
    const element = this.homepageDestinationsTarget;
    const items = data.slice(0, limit);

    element.innerHTML = "";

    items.forEach((item) => {
      var newFeaturedDestination = `<div class="small-feature col-sm-6 col-md-lg-3">
        <div class="img-zoom img rounded">
          <a href="${item.targetURL}">
            <img src="${item.imageURL}">
          </a>
          <span class="sr-only">${item.name}</span>
        </div>
        <div class="d-flex justify-content-between flex-column">
          <p class="title">${item.name}</p>
          <p>${item.description}</p>
        </div>
      </div>`;

      element.innerHTML += newFeaturedDestination;
    });
  }

  addedPostTargetConnected(element) {
    var sectionHeight = element.scrollHeight + 'px';
    element.style.height = 'calc('+sectionHeight+' + 1rem)';

    element.addEventListener('transitionend', function(e) {
      element.style.height = null;
      element.classList.remove('new-post');
    }, {once: true});
  }

  postTemplate(item, isNew = true) {
    var fireBaseTarget = isNew ? 'addedPost' : 'modifiedPost';
    var type = item.context == 'recentBlogPost' ? 'blog-post' : 'content-post';
    var elementId = type + '-' + item.id;
    var className = isNew ? 'new-post' : '';
    var newPost = `<div id="${elementId}" class="post-link-group d-flex justify-content-start ${className}" data-firebase-target="${fireBaseTarget}">
      <div class="link-group ml-2">
        <a class="title" href="${item.targetURL}">${item.title}</a>
        <p class="postdata date text-muted">
          <i class="far fa-clock"></i>
          ${item.subtitle}
        </p>
      </div>
    </div>`;

    return newPost;
  }

  featuredObjectQuery(context = ['homepageLarge', 'homepageSmall', 'homepageProfessionals', 'destinationsGateway']) {
    const environment = this.environmentValue;
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const featuredObjectRef = collection(serverDoc, 'featuredObject');
    const q = query(featuredObjectRef, where('context', 'in', context), orderBy('position', 'asc'));
    return q
  }

  recentEditorialQuery(context = ['recentBlogPost', 'recentContentPost']) {
    const environment = this.environmentValue;
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const featuredEditorialRef = collection(serverDoc, 'featuredEditorial');
    const q = query(featuredEditorialRef, where('context', 'in', context), orderBy('id', 'desc'));
    return q
  }

  // Profile Features
  profileFeatureQuery() {
    const environment = this.environmentValue;
    const accountID = this.accountValue;
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const profileFeature = collection(serverDoc, 'profileFeatures');
    const q = query(profileFeature, where('parentProfile', '==', parseInt(accountID)), orderBy('position', 'asc'));
    return q;
  }

  listingCardTemplate(item) {
    var elementId = 'listing-' + item.id;
    var regentClass = item.isRegent ? 'regent' : '';
    var newListingCard = `<div class="card-col">
      <div class="card search-card ${regentClass}" data-controller="search" data-listing-id="${item.id}" data-search-target="listingCard">
        <span class="status ${item.status}">${item.status}</span>
        <a class="stretched-link listing"
          style="background-image: url(${item.imageURL})"
          href="${item.targetURL}"></a>
        <div class="card-body">
          <h5 class="card-title">${item.formattedLocation}</h5>
          <p class="card-text text-muted">${item.formattedPrice}</p>
          <div class="card-text card-icons d-flex text-muted">
            <div class="col pl-0">
              <i class="far fa-bed mr-1 ${(item.beds && item.beds > 0) ? '' : 'd-none'}"> ${item.beds}</i>
              <i class="far fa-bath ${(item.baths && item.baths > 0) ? '' : 'd-none'}"> ${item.baths}</i>
            </div>
            <div class="col pr-0 text-right">
              <i class="far fa-street-view mr-1 ${item.hasStreetView ? '' : 'd-none'}"></i>
              <i class="far fa-video mr-1 ${item.hasVideo ? '' : 'd-none'}"></i>
              <i class="far fa-vr-cardboard mr-1 ${item.hasImmersiveTour ? '' : 'd-none'}"></i>
              <i class="far fa-door-open ${item.hasOpenHouse ? '' : 'd-none'}"></i>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    return newListingCard;
  }


  profileCardTemplate(item) {
    var elementId = 'profile-' + item.id;
    var regentClass = item.isRegent ? 'regent' : '';
    var newProfileCard = `<div class="card-col">
        <div class="card search-card ${regentClass}">
          <a class="stretched-link profile" style="background-image: url(${item.imageURL})" href="${item.targetURL}"></a>
          <div class="card-body">
            <h5 class="card-title">${item.name}</h5>
            <p class="card-text text-muted">${item.accountName}</p>
            <p class="card-text text-muted">${item.formattedLocation}</p>
          </div>
        </div>
      </div>
    </div>`;

    return newProfileCard;
  }


  // Root Firestore Document
  serverDomain() {
    switch (this.environmentValue) {
      case 'development':
        return window.location.hostname.replace('www.', '');
        break;
      case 'staging':
        return 'staging.luxre.net';
        break;
      default:
        return 'luxuryrealestate.com';
    }
  }

  get swiperController() {
    var swiperElement = document.getElementById('swiper-container');
    return this.application.getControllerForElementAndIdentifier(swiperElement, 'swiper');
  }

}

function createFnCounter(fn, invokeBeforeExecution) {
  let count = 0;
  return (snapshot) => {

    count++;
    if (count <= invokeBeforeExecution) {
      return null;
    }

    return fn(snapshot, count);
  }
}

function card_col_class(card_count) {
  switch(card_count) {
    case 6:
      return 'col-md-lg-2';
      break;
    case 5:
      return 'col-md-lg-1-5th';
      break;
    case 4:
      return 'col-md-lg-3';
      break;
    case 3:
      return 'col-md-lg-4';
      break;
    default:
      return "col-md-lg";
  }
}
