// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Turbo from "@hotwired/turbo"
Turbo.start();

import Rails from '@rails/ujs';
Rails.start();

import I18n from "i18n-js";

// Set up Stimulus
import "controllers"

// Import libs
import modernizr from 'modernizr'
import * as luxestrap from 'luxestrap'
import 'bootstrap'
import 'bootstrap-select'

// Import IE11 pollyfills for fetch & Promise
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch'

// Import custom modules
import calvin from 'calvin'
import etjs from 'etjs'
// import prefetchLinks from './prefetcher'
import ahoy from "ahoy.js";


// Import components
import './components/news/news'
import './components/search/search'

// Import styles
import '../stylesheets/application'

// Set global vars
global.jQuery = jQuery;
global.Rails = Rails;
global.luxestrap = luxestrap;
global.etjs = etjs;
global.I18n = I18n;

// Do stuff
document.addEventListener('turbo:load', function() {

  // Init Bootstrap jQuery plugins
  jQuery('*:not(.bootstrap-select) > .selectpicker').selectpicker({style: 'btn-theme btn-sm'});
  jQuery('.toast').toast('show');
  jQuery('[data-toggle="tooltip"]').tooltip();
  jQuery('[data-tooltip="toggle"]').tooltip();
  jQuery('.modal.open').modal({
    show: true
  });
  jQuery('#sharesheet').on('hide.bs.modal', function() {
    jQuery('#share-button').tooltip('dispose');
  });
  jQuery('#sharesheet').on('hidden.bs.modal', function() {
    document.focus();
    setTimeout(function() {
      jQuery('#share-button').tooltip('enable');
    }, 100);
  });

  // Init custom modules
  calvin.init({
      bootstrapButtonColor: 'secondary'
    , buttonContent: '<i class="far fa-calendar-plus"></i> Add To Calendar'
  });



  // Form validation
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var formsToValidate = document.getElementsByClassName('needs-validation');
  // Loop over them and prevent submission
  var validation = Array.prototype.filter.call(formsToValidate, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });

  // Prefetch links—create and update Turbo cached snapshots—on hover
  // prefetchLinks();
});

// Tidy up before Turbo cache
document.addEventListener('turbo:before-cache', function() {
  // Close mobile nav dropdown before caching page
  jQuery('.navbar-collapse').collapse('hide');

  jQuery('#home-search-type, .selectpicker').selectpicker('destroy').addClass('selectpicker');

  var bernieEl = document.getElementById('bernie');
  if (bernieEl) {
    bernieEl.innerHTML = "";
  }

});

String.prototype.titleCase = function() {
  return this.replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

// Printing
var beforePrint = function() {
  var printImages = document.querySelectorAll('.print-img img');
  [].forEach.call(printImages, (img) => {
    img.src = img.dataset.src;
  });
};

if (window.matchMedia) {
   var mediaQueryList = window.matchMedia('print');
   mediaQueryList.addListener(function(mql) {
       if (mql.matches) {
           beforePrint();
       }
   });
}

window.onbeforeprint = beforePrint;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
