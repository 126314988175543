import { register } from 'swiper/swiper-element-bundle';
// register Swiper custom elements
register();

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['largeFeature', 'showSlider', 'thumbSlider', 'thumbContainer', 'thumbSpan', 'fullscreenButton']

  static values = {
    effect: String,
    static: Boolean,
    preventHash: Boolean,
    thumbCount: Number,
    thumbPageCount: Number,
    currentThumbPage: Number
  }


  largeFeatureTargetConnected(element) {
    const swiperEl = document.querySelector('swiper-container');

    // swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      loop: true
    };

    Object.assign(swiperEl, swiperParams);
    let swiper = swiperEl.swiper;

    initInfoBoxes(swiper);

    var swiperVideos = document.querySelectorAll('swiper-slide video');
    document.addEventListener('turbo:before-cache', () => {
      [].forEach.call(swiperVideos, (video) => {
        video.remove();
      });
    });

  }

  showSliderTargetConnected(element) {
    this.initShowSlider(element);

    element.swiper.on('slideChange', (e) => {
      updatePageTitle(e, location.hash.replace('#', ''));
      this.trackPage();
    });

  }


  thumbSliderTargetConnected(element) {
    let that = this;

    const swiperEl = document.querySelector('swiper-container');

    // swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      loop: true
    };

    Object.assign(swiperEl, swiperParams);
    let swiper = swiperEl.swiper;

    swiper.on('slideChange', function (e) {
      let currentSlide = e.realIndex + 1;

      if (isNaN(currentSlide)) {
        currentSlide = 1;
        swiper.slideToLoop(0);
      }
      let newThumbSlide = Math.floor((currentSlide + 19) / 20);
      that.updateThumbsPage(newThumbSlide);
      that.analyticsController.trackPage();
    });

    document.addEventListener('keydown', function(event) {
      if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
        event.preventDefault();
      }
    });

    document.addEventListener('keyup', function(event) {
      var slidesContainer = document.getElementById('slides');
      var isFullScreen = slidesContainer.classList.contains('fullscreen');

      if (!isFullScreen) {

        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
          event.preventDefault();
        }

        let currentSlideIndex = swiper.realIndex;

        if (event.key == 'ArrowUp') {
          var newSlideIndex = swiper.realIndex - 4;
          newSlideIndex = newSlideIndex < 0 ? that.thumbCountValue - 1 : newSlideIndex;
          swiper.slideToLoop(newSlideIndex);
        }

        if (event.key == 'ArrowDown') {
          var newSlideIndex = swiper.realIndex + 4;
          newSlideIndex = newSlideIndex > that.thumbCountValue ? 0 : newSlideIndex;
          swiper.slideToLoop(newSlideIndex);
        }

      }

    });
  }

  updateThumbsPage(slideIndex) {
    this.thumbContainerTarget.classList.remove('page-' + this.currentThumbPageValue);
    this.thumbContainerTarget.classList.add('page-' + slideIndex);
    this.currentThumbPageValue = slideIndex;
    this.updatePageInfo();
  }

  advance() {
      let nextPage = this.currentThumbPageValue >= this.thumbPageCountValue ? 1 : this.currentThumbPageValue + 1
      this.updateThumbsPage(nextPage);
   }

  back() {
      let nextPage = this.currentThumbPageValue <= 1 ? this.thumbPageCountValue : this.currentThumbPageValue - 1
      this.updateThumbsPage(nextPage);
  }

  updatePageInfo() {
    if (this.hasThumbSpanTarget) {
      let start = ((this.currentThumbPageValue - 1) * 20) + 1;
      let end = (start + 19) > this.thumbCountValue ? this.thumbCountValue : (start + 19);
      let range =  start + ' - ' + end;
      this.thumbSpanTarget.innerText = range;
    }
  }

  trackPage() {
    var etjsElement = document.getElementById('et');
    var analyticsController = this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics');
    etjs.trackPage();
  }

  fullscreenButtonTargetConnected() {
    document.addEventListener('fullscreenchange', fullscreenHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenHandler);
    document.addEventListener('mozfullscreenchange', fullscreenHandler);
    document.addEventListener('MSFullscreenChange', fullscreenHandler);

    // Setup keyboard shortcuts
    document.addEventListener('keydown', addKeyboardShortcuts);

    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keydown', addKeyboardShortcuts);
    });
  }

  toggleFullscreen () {
    var el = document.getElementById('slides');
    var isFullScreen = el.classList.contains('fullscreen');

    if (modernizr.fullscreen) {
      isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

      el.classList.add('transitioning');

      setTimeout(function() {
        el.classList.remove('transitioning');
      }, 800);
    }

    if (!isFullScreen) {
      enterFullscreen(el);
     } else {
       exitFullscreen(el);
     }

  }

  get analyticsController() {
    var etjsElement = document.getElementById('et');
    return this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics')
  }
}

function updatePageTitle(slider, position) {
  if (typeof(slider)==='undefined') slider = swiper;
  if (typeof(position)==='undefined') position = false;
  var caption = jQuery('#caption-' + slider.realIndex).text();
  var sliderCount = slider.slides.length - 2; // preloading adds extra slides at the beginning and end so we remove two from slide count
  if (position) {
    position = parseInt(position);
    position = (position > 0 && position <= sliderCount && position != '') ? position : false;
  }
  var currentSlide = slider.realIndex + 1;
  caption = caption == '' ? ': ' : ': ' + caption + ' — ';
  var regex = /\d+\/\d+:/;
  var newTitle = currentSlide + '/' + sliderCount + caption + document.title.replace(regex, '');

  document.title = newTitle.slice(0, 70);
}

function addParallax() {
  var scroll = window.scrollY;
  if (scroll < window.innerHeight ) {
    jQuery('.slider.featured .swiper-slide').css('top', scroll/2);
  }
}

function setupSliderInteractions(slider, element) {

  document.getElementById('swiper-fullscreen').addEventListener('click', function() {

    var isFullScreen = element.classList.contains('fullscreen');
    if (modernizr.fullscreen) {
      isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

      element.classList.add('transitioning');

      setTimeout(function() {
        element.classList.remove('transitioning');
      }, 800);
    }

    if (slider) {
      if (!isFullScreen) {
        enterFullscreen(element, slider);
      } else {
        exitFullscreen(slider);
      }
    }
  });

  document.addEventListener('fullscreenchange', fullscreenHandler);
  document.addEventListener('webkitfullscreenchange', fullscreenHandler);
  document.addEventListener('mozfullscreenchange', fullscreenHandler);
  document.addEventListener('MSFullscreenChange', fullscreenHandler);

  // Setup keyboard shortcuts
  document.addEventListener('keydown', addKeyboardShortcuts);

  document.addEventListener('turbo:before-cache', function() {
    document.removeEventListener('keydown', addKeyboardShortcuts);
  });

}


function addKeyboardShortcuts(e) {
  if(bodyHasFocus()) {
    if (e.which == 70 && !e.ctrlKey && !e.metaKey) { // F for fullscreen
      enterFullscreen();
    } else if (e.which >= 49 && e.which <= 52) { // 1-4 to navigate slider tabs (photos, 3DTours, video, streetview, etc.)
      triggerAccessKey(e.key);
    }
  }
}

function fullscreenHandler() {
  var sliderContainer = document.getElementById('slides');
  if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
    sliderContainer.classList.remove('fullscreen');
    exitFullscreen();
  } else {
    sliderContainer.classList.add('fullscreen');
    enterFullscreen();
  }
}

function enterFullscreen(slider) {
  var sliderContainer = document.getElementById('slides');
  if (sliderContainer.requestFullscreen) {
    sliderContainer.requestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.mozRequestFullScreen) { /* Firefox */
    sliderContainer.mozRequestFullScreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    sliderContainer.webkitRequestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.msRequestFullscreen) { /* IE/Edge */
    sliderContainer.msRequestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else {
    jQuery('body').addClass('fullscreen');
    sliderContainer.classList.add('fullscreen');
  }

  // Set caption position
  if (modernizr.fullscreen) {
    setTimeout(function() {
      if (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) {
        var scheight = jQuery('.swiper-container').height();
        var swheight = jQuery('.swiper-wrapper').height();

        var captionOffset = swheight - scheight;
        jQuery('.swiper-caption').css({
          'position': 'relative',
          'bottom': captionOffset + 'px'
        });
        if (slider) {
          slider.updateSize()
        }
      }

    }, 800);
  }

}

function exitFullscreen(slider) {


  exitFullscreenHandler();
  if (document.exitFullscreen)  {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    document.body.classList.remove('fullscreen')
    exitFullscreenHandler();
  }


  // Reset caption position
  if (modernizr.fullscreen) {
    setTimeout(function() {
        if (!document.fullScreen || !document.mozFullScreen || !document.webkitIsFullScreen) {
            jQuery('.swiper-text').css('position', 'static');
          }
        }, 800);
  }

}

function bodyHasFocus() {
  return document.activeElement.tagName == 'BODY'
}

function exitFullscreenHandler(slider) {

  var sliderContainer = slider ? slider : document.getElementById('slides')
  sliderContainer.classList.remove('fullscreen');

  if (modernizr.fullscreen) {
    var captions = document.getElementsByClassName('swiper-caption');
    [].forEach.call(captions, function(caption){
      caption.style.position = 'static';
    })
  }
}

function triggerAccessKey(key) {
  var link = document.querySelector('a[accesskey="'+key+'"]');
  if (link) {
    link.click();
  }
}

function initInfoBoxes(swiper) {
  var firstInfobox = document.getElementById('info-box0');
  if (firstInfobox) {
    firstInfobox.classList.add('active');
  }

  if (document.querySelector('.info-box.active')) {
    swiper.on('slideNextTransitionStart', function() {
      var currentIndex = this.realIndex;
      var currentSlide = this.slides[this.realIndex];
      document.querySelector('.info-box.active').classList.remove('active', 'next', 'prev');
      document.querySelector('.info-box.slide-' + currentIndex).classList.add('active', 'next');
    });
    swiper.on('slidePrevTransitionStart', function() {
      var currentIndex = this.realIndex;
      var currentSlide = this.slides[this.realIndex];
      document.querySelector('.info-box.active').classList.remove('active', 'next', 'prev');
      document.querySelector('.info-box.slide-' + currentIndex).classList.add('active', 'prev');
    });
  }

  // Setup current location search
  var locationSearch = document.getElementById('current-location-search');

  if (locationSearch) {
    if (window.location.protocol == 'http:') {
      locationSearch.classList.add('d-none');
    }
  }

  // Add parallax to slider
  window.addEventListener('scroll', addParallax);

  document.addEventListener('turbo:before-cache', function() {
    document.querySelector('.info-box.active').classList.remove('active', 'next', 'prev');
    document.querySelector('.info-box.slide-0').classList.add('active', 'next');
  });

}

function fixSliderHeight() {
  var featuredSlider = document.querySelector('.slider.featured');
  var windowHeight = window.innerHeight;
  var windowWidth = window.innerWidth;

  if (featuredSlider) {
    var featuredWidth = featuredSlider.offsetWidth;
    var featuredHeight = featuredSlider.offsetHeight;
    var bottomPadding = featuredSlider.classList.contains('hero') ? 132 : 50;

    if ((windowHeight - bottomPadding) / windowWidth < 9 / 16) {
      featuredSlider.classList.add('static-height');
    } else {
      featuredSlider.classList.remove('static-height');
    }
  }
}
