import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['toggleButton', 'link', 'searchField', 'searchButton']

  toggleAllAwards() {
    var legacyAwardLinks = document.querySelectorAll('.award-link.legacy-award');
    var toggleButton = document.getElementById('toggle-button');
    var buttonParent = document.getElementById('show-more');

    var showing = this.data.get('showing');
    [].forEach.call(legacyAwardLinks, (award) => {
      if (showing == 'legacy') {

        award.classList.remove('show');
        this.data.set('showing', 'current');
        toggleButton.textContent = "Show More Awards";
        buttonParent.classList.remove('dropup');

      } else {

        award.classList.add('show');
        this.data.set('showing', 'legacy');
        toggleButton.textContent = "Show Current Awards";
        buttonParent.classList.add('dropup');

      }
    });
  }

  filterSearch(e) {
    var query = document.getElementById('award-search').value;

    if (location.pathname != "/awards/search") {
      window.location = '/awards/search?q='+encodeURIComponent(query.toLowerCase());
    } else {
      this.updateSearchQuery(query);
    }
  }

  updateSearchQuery(query) {

    var awardSections = document.querySelectorAll('.award-section');
    var profileCards = document.querySelectorAll('.card-wrapper');
    var visibleEventId;

    [].forEach.call(awardSections, (section) => {
      var eventNames = section.querySelectorAll('.event-name');
      section.classList.add('d-none');

      var childs = section.childNodes;

      visibleEventId = "";
      [].forEach.call(eventNames, (eventName) => {

        var eventId = eventName.dataset.event;
        if ( eventName.dataset.search.includes( query.toLowerCase() ) ) {

          if (eventId != visibleEventId) {
            eventName.classList.remove('d-none');
            visibleEventId = eventId;
          }

        } else {
          eventName.classList.add('d-none');
        }

      });
    });

    [].forEach.call(profileCards, (card) => {
      if (card.dataset.search.includes(query.toLowerCase())) {
        card.classList.remove('d-none');
        card.parentElement.parentElement.classList.remove('d-none');
      } else {
        card.classList.add('d-none');
      }
    });

    history.pushState({}, "", '?q=' + query.toLowerCase())

  }

}
