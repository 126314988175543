document.addEventListener('turbo:load', function() {


  // Only open one mobile search section at a time
  jQuery('.collapse-mobile').on('show.bs.collapse', function(){
    jQuery('.collapse-mobile').collapse('hide');
  });

  var locationSelect = document.querySelectorAll('select.location');
  [].forEach.call(locationSelect, function(select) {
    select.addEventListener('change', function(e) {
      showSubdivisions(this.value);
    })
  });

});


function showSubdivisions(source) {
  var subdivisions = Array.from(document.querySelectorAll('.subdivisions'));
  var areaValue = source;

  if (areaValue && areaValue.length > 2) {
    switch (areaValue.substring(0,2)) {
      case 'AU':
        hideSubdivisions();
        jQuery('.country').val('AU');
        jQuery('.country').selectpicker('val', 'AU');
        jQuery('.AU-subdivisions').val(areaValue);
        jQuery('.AU-subdivisions').removeClass('d-none');
        break;
      case 'CA':
        hideSubdivisions();
        jQuery('.country').val('CA');
        jQuery('.country').selectpicker('val', 'CA');
        jQuery('.CA-subdivisions').val(areaValue);
        jQuery('.CA-subdivisions').removeClass('d-none');
        break;
      case 'US':
        hideSubdivisions();
        jQuery('.country').val('US');
        jQuery('.country').selectpicker('val', 'US');
        jQuery('.US-subdivisions').removeClass('d-none');
        jQuery('.US-subdivisions').val(areaValue);
    }
  } else {
    switch (areaValue) {
      case 'AU':
        hideSubdivisions();
        jQuery('.country').val('AU');
        jQuery('.country').selectpicker('val', 'AU');
        jQuery('.AU-subdivisions').removeClass('d-none');
        break;
      case 'CA':
        hideSubdivisions();
        jQuery('.country').val('CA');
        jQuery('.country').selectpicker('val', 'CA');
        jQuery('.CA-subdivisions').removeClass('d-none');
        break;
      case 'US':
        hideSubdivisions();
        jQuery('.country').val('US');
        jQuery('.country').selectpicker('val', 'US');
        jQuery('.US-subdivisions').removeClass('d-none');
        break;
      default:
        hideSubdivisions();
        jQuery('.country').val(areaValue);
        jQuery('.country').selectpicker('val', areaValue);
    }
  }
  document.getElementById('area').value = areaValue;
}

function hideSubdivisions(selectValueToIgnore = false) {
  var subdivisions = document.querySelectorAll('.subdivisions');
  [].forEach.call(subdivisions, function(subdivision) {
    subdivision.classList.add('d-none');
    subdivision.value = '';
  });
}
