import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['auto', 'light', 'dark'];

  initialize() {
    this.activateTheme(this.theme);

    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    mql.addEventListener("change", () => {
      this.updateLogos();
    });
  }

  activateTheme(theme) {
    // Add class to body
    var body = document.body;
    body.classList.remove('dark', 'light', 'auto');
    body.classList.add(this.theme);

    // Update selected theme in menu
    document.querySelectorAll('.theme-switcher .theme-link').forEach(node => {
      if (node.classList.contains(this.theme)) {
        node.classList.add('selected');
      } else {
        node.classList.remove('selected');
      }
    });

    this.updateLogos();
  }

  updateTheme() {
    this.theme = this.data.get('selected');
    this.activateTheme(this.theme);
    this.broadcastThemeChange();
  }

  updateLogos() {
    var theTheme = this.theme
    if(theTheme == 'auto') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theTheme = 'dark';
      } else {
        theTheme = 'light';
      }
    }
    if(theTheme == 'dark') {
      document.querySelectorAll('*[data-dark-mode-image-url]').forEach(function (element) {
        if(element.tagName == 'IMG') {
          element.setAttribute('src',element.getAttribute('data-dark-mode-image-url'));
        } else {
          element.style.backgroundImage = "url('" + element.getAttribute('data-dark-mode-image-url') + "')";
        }
      });
    } else if(theTheme == 'light') {
      document.querySelectorAll('*[data-light-mode-image-url]').forEach(function (element) {
        if(element.tagName == 'IMG') {
          element.setAttribute('src',element.getAttribute('data-light-mode-image-url'));
        } else {
          element.style.backgroundImage = "url('" + element.getAttribute('data-light-mode-image-url') + "')";
        }
      });
    }
  }

  broadcastThemeChange() {
    const event = document.createEvent('Event');
    event.initEvent('themeChange', true, true);
    document.dispatchEvent(event);
  }

  set theme(value) {
    window.localStorage.setItem('theme', value);
  }

  get theme() {
    var savedTheme = window.localStorage.theme;
    var selectedTheme = savedTheme == 'dark' || savedTheme == 'light' || savedTheme == 'auto' ? savedTheme : 'auto';
    return selectedTheme;
  }
}
